import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Card } from '@material-ui/core';
import numberWithCommas from '../../../../../utils/numberToComma';
import { useAppMetafireUtilities } from '../../../../../metafire/hooks/useAppMetafireUtilities';
import { getTextColourForCategory } from '../../intervention/utils';

const capitalizeOpex = (str, searchValue) => {
  if (str.includes(searchValue)) {
    let result = str.replace(searchValue, searchValue.toUpperCase());
    return result;
  } else {
    return str;
  }
};

function SecondaryImpactCard(props) {
  const useStyles = makeStyles((theme) => ({
    paper1: {
      outline: 'none',
      width: '100%',
      // width: props.width,
      // height: props.height,
      padding: theme.spacing(5, 2, 5, 0),
      background: '#FFFFFF',
      borderRadius: '10px',
      boxShadow: '2px 2px 8px rgba(24, 50, 119, 0.1)',
    },
    alignTypo: {
      marginTop: '15%',
    },
    alignUnits: {
      paddingLeft: 10,
    },
    typo1: {
      color: '#3BC7EE',
    },
    typo2: {
      color: '#26E0A3',
    },
  }));
  const classes = useStyles();
  const { getValue, getLabel, getUnit, utils } = useAppMetafireUtilities();
  const { inputs } = props;
  return (
    <>
      <Card className={classes.paper1}>
        <Grid container direction="row" xs={12}>
          {inputs?.map((inputItem) => {
            const decimalPlace = utils.getDecimalPlacesByTag(utils.getUiRoundTag(inputItem));
            return inputItem?.value?.map((valueItem) => (
              <>
                <Grid xs={2} container alignItems="center" direction="column">
                  <Typography
                    style={{ textAlign: 'center', marginTop: '3%', height: '40px', display: 'flex', lineHeight: '1.5', alignItems: 'flex-start' }}
                    color="textSecondary"
                    variant="h6"
                  >
                    {getLabel(valueItem?.resource)}
                  </Typography>
                  <Typography className={classes.alignTypo} variant="h4">
                    <b style={{ color: getTextColourForCategory(valueItem.category) }}>{numberWithCommas(getValue(valueItem?.resource, decimalPlace) || 0)}</b>
                  </Typography>
                  <Typography className={classes.alignTypo} color="textSecondary">
                    {getUnit(valueItem?.resource)}
                  </Typography>
                </Grid>

                <Grid xs={2} container alignItems="center" direction="column">
                  <Typography
                    style={{ textAlign: 'center', marginTop: '3%', height: '40px', display: 'flex', lineHeight: '1.5', alignItems: 'flex-start' }}
                    color="textSecondary"
                    variant="h6"
                  >
                    {getLabel(valueItem?.opex)}
                  </Typography>
                  <Typography className={classes.alignTypo} variant="h4">
                    <b style={{ color: getTextColourForCategory(valueItem.category) }}>{numberWithCommas(getValue(valueItem?.opex, decimalPlace) || 0)}</b>
                  </Typography>
                  <Typography className={classes.alignTypo} color="textSecondary">
                    {getUnit(valueItem?.opex)}
                  </Typography>
                </Grid>
              </>
            ));
          })}
        </Grid>
      </Card>
    </>
  );
}
export default SecondaryImpactCard;
