import wasteActiveicon from '../../../../assets/interventions/wasteActivated.svg';
import waterActiveicon from '../../../../assets/interventions/waterActivated.svg';
import energyActiveicon from '../../../../assets/interventions/energyActivated.svg';
import materialActiveicon from '../../../../assets/interventions/materials/active-materials.svg';

export const getEnabledCount = (custom, interv) => {
  let intervCount = 0;
  let customCount = custom;
  interv
    .map((arr) => arr.length)
    .forEach((D) => {
      intervCount = intervCount + D;
    });
  return customCount + intervCount;
};

export const calculateEnabledCount = (data) => {
  const interv = data?.subcategories?.filter((interventions) => interventions?.name !== 'Custom Interventions');
  const custom = data?.subcategories?.filter((interventions) => interventions?.name === 'Custom Interventions');
  const customCount = custom[0]?.interventions?.filter((item) => item?.enabled === true)?.length;
  const intervCount = interv?.map((intervention) => intervention?.interventions?.filter((item) => item?.enabled === true));
  return getEnabledCount(customCount, intervCount);
};

export const getTotalInterventionsInCategory = (data = []) => {
  return data?.subcategories?.reduce((memo, sbCategory) => {
    memo += sbCategory?.interventions?.length || 0;
    return memo;
  }, 0);
};

export const getEnabledInterventionsInCategory = (data = []) => {
  return data?.subcategories?.reduce((memo, sbCategory) => {
    sbCategory?.interventions.forEach((intervention) => {
      if (intervention?.quantities?.enabled?.value === true) {
        memo.push(intervention);
      }
    });
    return memo;
  }, []);
};

export const getEnabledAndActivatedInterventionsInCategory = (data = []) => {
  return data?.subcategories?.reduce((memo, sbCategory) => {
    sbCategory?.interventions.forEach((intervention) => {
      if (intervention?.quantities?.enabled?.value === true && intervention?.quantities?.activated?.value === true) {
        memo.push(intervention);
      }
    });
    return memo;
  }, []);
};

export const getActivatedInterventionsInCategory = (data = []) => {
  return data?.subcategories?.reduce((memo, sbCategory) => {
    sbCategory?.interventions.forEach((intervention) => {
      if (intervention?.quantities?.activated?.value === true) {
        memo.push(intervention);
      }
    });
    return memo;
  }, []);
};

export function capitalizeFilterValues(products = [], keyName) {
  if (Array.isArray(products)) {
    return products.reduce((memo, product) => {
      if (product?.filters && product?.filters[keyName]) {
        let cloneProduct = Object.assign({}, product);
        const updatedValues = cloneProduct.filters[keyName].map((value) => {
          return value
            .split(' ')
            .map((word) =>
              word
                .split('-')
                .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
                .join(' ')
            )
            .join(' ');
        });
        cloneProduct.filters[keyName] = updatedValues;
        memo.push(cloneProduct);
      } else {
        memo.push(product);
      }
      return memo;
    }, []);
  } else {
    console.error('The input is not an array of products');
    return products;
  }
}
export function getLabelDynamically(name) {
  return name === 'Materials' ? 'Material' : name;
}

export function sortByOrderedIds(response, orderedIds) {
  return orderedIds.reduce((acc, id) => {
    const item = response.find((item) => item.id === id);
    return item ? [...acc, item] : acc;
  }, []);
}

export function sortResponse(response, key) {
  if (!Array.isArray(response)) return response;

  switch (key) {
    case 'spv':
    case 'spvApartment':
    case 'spvClubhouse':
    case 'spvVilla':
    case 'spvPenthouse':
    case 'wwm': {
      const systemCapacityItem = response.find((item) => item.label === 'System Capacity');
      return systemCapacityItem ? [systemCapacityItem, ...response.filter((item) => item.label !== 'System Capacity')] : response;
    }

    case 'hwm':
    case 'hwmVilla':
    case 'hwmApartment':
    case 'hwmClubhouse':
    case 'hwmPenthouse': {
      const systemItem = response.find((item) => item.label === 'System');
      return systemItem ? [systemItem, ...response.filter((item) => item.label !== 'System')] : response;
    }

    case 'twr': {
      const orderedIds = ['twr__pi_userStpCapacity', 'twr__pi_userRoCapacity'];
      const percentageItems = response.filter((item) => item.unit === '%');
      const orderedItems = sortByOrderedIds(response, orderedIds);
      return [...orderedItems, ...percentageItems];
    }

    case 'nstp': {
      const orderedIds = ['nstp_stpCapacity', 'nstp_nstpArea'];
      return sortByOrderedIds(response, orderedIds);
    }

    case 'csp': {
      const orderedIds = ['csp_dailyDryWaste', 'csp_dailyMedicalWaste', 'csp_dailyEWaste', 'csp_areaRequired'];
      return sortByOrderedIds(response, orderedIds);
    }

    case 'structuralSteel': {
      const orderedIds = ['structuralSteel__pi_steelQuantity', 'structuralSteel_typeOfSteel'];
      return sortByOrderedIds(response, orderedIds);
    }

    case 'rrwh': {
      const orderedIds = ['rrwh__pi_tankSize', 'rrwh_daysServiced', 'rrwh_annualRechargedWater'];
      return sortByOrderedIds(response, orderedIds);
    }

    case 'srwh': {
      const orderedIds = ['srwh__pi_tankSize', 'srwh_daysServiced', 'srwh_annualRechargedWater'];
      return sortByOrderedIds(response, orderedIds);
    }

    default:
      return response;
  }
}
export function getGoalContributionCardInfo(feature) {
  const category = getCategoryByFeature(feature);
  if (category === 'energy') return { color: '#F28D14', text: 'Net Zero Energy', icon: energyActiveicon };
  if (category === 'water') return { color: '#3BC7EE', text: 'Net Zero Water', icon: waterActiveicon };
  if (category === 'waste') return { color: '#BC8A5F', text: 'Net Zero Waste', icon: wasteActiveicon };
  if (category === 'materials') return { color: '#916AAC', text: 'Net Zero Embodied Carbon', icon: materialActiveicon };
}
export function getTextColourForIntervention(feature) {
  if (
    [
      'twr',
      'nstp',
      'efficientFixturesDualFlush',
      'efficientFixturesShower',
      'efficientFixturesKitchenSink',
      'efficientFixturesHealthFaucet',
      'efficientFixturesFaucet',
      'swm',
    ].includes(feature)
  )
    return '#3BC7EE';
  if (
    ['hwmVilla', 'hwmApartment', 'hwmClubhouse', 'hwmSwimmingPool', 'hwmPenthouse', 'spvApartment', 'spvClubhouse', 'spvVilla', 'spvPenthouse'].includes(
      feature
    )
  )
    return '#FFAC49';
  if (
    [
      'exteriorPaintsSunny',
      'exteriorPaintsNonSunny',
      'indoorFlooringUnB',
      'indoorFlooringBathroom',
      'indoorFlooringKitchen',
      'indoorFlooringBedroom',
      'indoorFlooringLnD',
    ].includes(feature)
  )
    return '#916AAC';
  if (['wwm', 'csp'].includes(feature)) return '#BC8A5F';
  return '#000000';
}

export function getTextColourForCategory(category) {
  if (['energy'].includes(category.toLowerCase())) return '#FFAC49';
  if (['waste'].includes(category.toLowerCase())) return '#BC8A5F';
  if (['water'].includes(category.toLowerCase())) return '#3BC7EE';
  if (['material'].includes(category.toLowerCase())) return '#916AAC';
  return '#000000';
}

export function orderCategory(data) {
  const presetCategoryOrder = ['Energy', 'Water', 'Waste', 'Materials'];
  return (
    data &&
    data
      ?.filter((category) => presetCategoryOrder.includes(category?.name))
      .sort((a, b) => presetCategoryOrder.indexOf(a.name) - presetCategoryOrder.indexOf(b.name))
  );
}
export const formatString = (dataObj) => {
  const keys = Object.keys(dataObj);
  let dataS = '';
  keys.forEach((d, i) => {
    if (i !== keys.length - 1) return (dataS += `${d}: ${dataObj[d]},`);
    else return (dataS += `${d}: ${dataObj[d]}`);
  });
  return dataS;
};
export const formatStringToObject = (stringValue) => {
  /*this function converts data stored as string to an object which can be consumed by the table component*/
  /*storing data as an object is ideal but until then this function can be used*/
  const stringArray = stringValue.split('\n');
  return stringArray.map((value) => {
    if (value.includes('http') || value.includes('www')) {
      return {
        subtype: 'url',
        name: value.split(': ')[0],
        value: value.split(': ')[1],
      };
    } else {
      return {
        name: value.split(': ')[0],
        value: value.split(': ')[1],
      };
    }
  });
};
export const ProductFilterKeys = {
  brand: '#brand',
  variant: '#variant',
  capacity: '#capacity',
  eco_labels: '#eco_labels',
  subcategory: '#subcategory',
  system_types: '#system_types',
  solutions: '#solutions',
  panel_type: '#panel_type',
  application: '#application',
  inverter_type: '#inverter_type',
  capacity_range: '#capacity_range',
  panel_capacity: '#panel_capacity',
};

export const getFilterDetailsForProduct = (data = {}, filterByValues = [], filterByKeys = []) => {
  let filter = null;
  const { product = {}, category = null, subCategory = null } = data;
  const categoryValue = category;
  const subCategoryValue = subCategory;

  if (filterByKeys && filterByKeys.length) {
    filter =
      product?.filters &&
      Object.entries(product?.filters)
        .filter(([key, value]) => filterByKeys.includes(key) && value.length > 0 && value !== null && value !== undefined)
        .map(([, value]) => value.filter((v) => v))
        .flat()
        .join(',');
  }

  if (filterByValues && filterByValues.length) {
    filter = filterByValues.flat().join(',');
  }

  return { category: categoryValue, subCategory: subCategoryValue, filter };
};

export function getCategoryByFeature(featureKey) {
  const energy = ['hwmApartment', 'hwmVilla', 'hwmPenthouse', 'hwmClubhouse', 'hwmSwimmingPool', 'spvApartment', 'spvVilla', 'spvPenthouse', 'spvClubhouse'];
  const water = [
    'efficientFixturesDualFlush',
    'efficientFixturesFaucet',
    'efficientFixturesHealthFaucet',
    'efficientFixturesKitchenSink',
    'efficientFixturesShower',
    'twr',
    'nstp',
    'swm',
    'rrwh',
    'srwh',
  ];
  const materials = [
    'indoorFlooringBedroom',
    'indoorFlooringKitchen',
    'indoorFlooringBathroom',
    'indoorFlooringLnD',
    'indoorFlooringUnB',
    'sc',
    'structuralSteel',
    'exteriorPaintsSunny',
    'exteriorPaintsNonSunny',
    'interiorPaints',
  ];
  const waste = ['wwm', 'csp'];
  if (energy.includes(featureKey)) return 'energy';
  if (water.includes(featureKey)) return 'water';
  if (materials.includes(featureKey)) return 'materials';
  if (waste.includes(featureKey)) return 'waste';
  return '';
}

export function getWarningText(warnings, item) {
  if (!warnings || !item) {
    return null;
  }
  for (const warning of warnings) {
    if (warning.value === false) {
      continue;
    }
    const contextTag = warning.tags.find((tag) => tag.startsWith('context:'));
    if (contextTag) {
      const contextValue = contextTag.split(':')[1];
      if (contextValue !== item.id) {
        continue;
      }
    }
    const warningTextTag = item.tags.find((tag) => tag.startsWith('warningText:'));
    if (warningTextTag) {
      return warningTextTag.split(':')[1];
    }
  }
  return null;
}
export function getFileKeyByUrl(url) {
  if (!url.includes('custom-interventions')) {
    return null;
  }
  const domainEndIndex = url.indexOf('/', url.indexOf('//') + 2);
  if (domainEndIndex === -1) {
    return null;
  }
  const queryParamsStartIndex = url.indexOf('?');
  const keyEndIndex = queryParamsStartIndex === -1 ? url.length : queryParamsStartIndex;

  return url.substring(domainEndIndex + 1, keyEndIndex);
}

export const normalizeFileName = (name) => {
  return name
    .trim()
    .replace(/\s*_\s*/g, '_')
    .replace(/\s+/g, '');
};
