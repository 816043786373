/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';

import { Typography, Grid, makeStyles } from '@material-ui/core';

import numberWithCommas from '../../../../../utils/numberToComma';

import { useInterventionDetailsContext } from '../context/InterventionDetailsV2';
import useAppProjectInputKeysByInterventions from './ProjectInputsByCategories';

const useStyles = makeStyles((theme) => ({
  projectInputTitle: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '19.2px',
    letterSpacing: '0.71px',
    color: 'rgba(46, 46, 46, 1)',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
  },
  projectInputValue: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.71px',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '10px',
  },
  projectInputLabel: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.71px',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '10px',
    color: 'rgba(102, 102, 102, 1)',
  },
}));

const ProjectInputs = () => {
  const classes = useStyles();
  const {
    key: intervention,
    get: { getQuantityValue, getQuantityLabel, getQuantityUnit },
    schema,
  } = useInterventionDetailsContext();
  const projectInputsData = useAppProjectInputKeysByInterventions({ schema, key: intervention });

  const projectInputs = useMemo(() => {
    const { subCategoryKey = null, villaInputs = [], inputs = [], penthouseEnabledInputs = [] } = projectInputsData;

    const isPenthouseEnabled = getQuantityValue(schema[`${intervention}_penthouseEnabled`]);
    const subCategory = getQuantityValue(schema[subCategoryKey || `${intervention}_subcategory`]);

    if (subCategory === 'apartment' && isPenthouseEnabled && isPenthouseEnabled !== '-') {
      return penthouseEnabledInputs;
    } else if (subCategory === 'villa') {
      return villaInputs;
    } else {
      return inputs;
    }
  }, [projectInputsData]);

  const formatValue = (pi) => {
    const value = getQuantityValue(pi);

    // if number, return numberWithCommas
    if (!isNaN(parseFloat(value))) return numberWithCommas(value);

    return value;
  };

  const columnMaxLength = Math.max(...projectInputs.map((pi) => pi.length));

  return (
    <Grid container item xs={12} direction="column">
      {projectInputs?.map((piRow) => (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: `repeat(${columnMaxLength}, 1fr)`,
            gap: '1%',
            padding: '1%',
          }}
        >
          {piRow.map((pi) => (
            <div key={pi}>
              <Typography className={classes.projectInputLabel}>{getQuantityLabel(pi)}</Typography>
              <Typography className={classes.projectInputValue}>
                {formatValue(pi)} {getQuantityUnit(pi)}
              </Typography>
            </div>
          ))}
        </div>
      ))}
    </Grid>
  );
};

export default ProjectInputs;
