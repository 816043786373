/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import InterventionDetailsContext, { useInterventionDetailsContext } from './context/InterventionDetailsV2';
import ApiError from '../../../common/Error/ApiError';
import CompareInterventionSets from './interventionSets/compareInterventionSets';

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: '56px',
    padding: theme.spacing(3),
    position: 'relative',
  },
  root: {
    paddingTop: '3%',
  },
  overview: {
    flexGrow: '1',
    maxWidth: '100%',
    flexBasis: '100%',
  },
  rightOverview: {
    marginTop: '1%',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    maxWidth: 900,
    minWidth: 700,
    minHeight: 400,
    padding: theme.spacing(2, 4, 3),
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
    borderRadius: '10px',
  },
  paperModal: {
    position: 'absolute',
    outline: 'none',
    height: 'auto',
    width: 'auto',
    backgroundColor: '#FFFFFF',
    borderRadius: '15px',
    padding: theme.spacing(2, 4, 2),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  vendorGrid: {
    marginTop: '3%',
  },
  close: {
    marginLeft: '95%',
  },
  backdrop: {
    zIndex: 1,
    color: '#fff',
  },
}));

function CompareIntervention() {
  const { request } = useInterventionDetailsContext();
  const classes = useStyles();

  if (request?.isError) {
    return (
      <Grid className={classes.main}>
        <ApiError />
      </Grid>
    );
  }
  return (
    <Grid className={classes.main}>
      <Backdrop className={classes.backdrop} style={{ zIndex: 3, color: '#66E3BE' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CompareInterventionSets />
    </Grid>
  );
}

const CompareInterventionContextComponent = (props) => {
  return (
    <InterventionDetailsContext>
      <CompareIntervention {...props} />
    </InterventionDetailsContext>
  );
};

export default CompareInterventionContextComponent;
