import { useInterventionDetailsContext } from '../context/InterventionDetailsV2';
import InterventionParametersOverview from '../components/InterventionParametersOverview';
import InterventionResultsOverview from '../components/InterventionResultsOverview';
import InterventionOverview from '../components/InterventionOverview';
import InterventionImpactAndGoals from '../components/InterventionImpactAndGoals';
import { sortResponse, getCategoryByFeature } from '../../intervention/utils';
import { MetafireInterventions } from '../../../../../metafire/utils/types';

const CategoryColors = {
  energy: {
    circlecolor: '#F28D14',
    textcolor: '#F28D14',
    color: ['#FFC971CC', '#F9C74F', '#F9844A', '#F94144', '#C32F27', '#9F2622', '#7C1E1C', '#591614', '#3A0E0E', '#200808'],
  },
  waste: {
    circlecolor: '#BC8A5F',
    textcolor: '#BC8A5F',
    color: ['#F3DDC8', '#E29D69', '#BC8A5F', '#DF530D', '#C24100', '#9E3600', '#7B2B00', '#592200', '#3D1600', '#231000'],
  },
  materials: {
    circlecolor: '#BF55FF',
    textcolor: '#BF55FF',
    color: ['#6300A5', '#BF55FF', '#DA9AFF', '#A744E4', '#8F34CC', '#7724B5', '#60189E', '#4B0E89'],
  },
  water: {
    circlecolor: '#91E6FA',
    textcolor: '#91E6FA',
    color: ['#CAF0F8', '#90E0EF', '#00B4D8', '#0077B6', '#03045E', '#005a9e', '#004280', '#002d63', '#001847', '#000d2b'],
  },
};

// TODO: Rename all releavant components and Simplify props
const InterventionDetailsSummary = ({ projectId, interventionId }) => {
  const { intervention: interventiondetails, projectIntervention, meta, key } = useInterventionDetailsContext();
  const category = getCategoryByFeature(interventiondetails?.feature);
  const {
    quantities,
    capexTotalValue,
    capexTotalConvertedValue,
    customizable,
    warning,
    mandateSummary,
    result1,
    result2,
    sdgNumbers,
    sdgText,
    capexConverted,
    goalsProgressPercentage,
  } = meta;
  const reorderedInput = sortResponse(customizable, key);
  return (
    <div>
      <InterventionParametersOverview
        title={'Intervention Parameters'}
        warning={warning}
        inputs={reorderedInput}
        mandateSummary={mandateSummary}
        selectedInterventionDetails={quantities}
        projectId={projectId}
        interventionId={interventionId}
        projectIntervention={projectIntervention}
      />
      <InterventionResultsOverview
        capexTotalValue={capexTotalValue}
        capexTotalConvertedValue={capexTotalConvertedValue}
        title1={'Intervention Results'}
        title2={'Show CAPEX(total)'}
        capexConverted={capexConverted}
        result1={result1}
        result2={result2}
        selectedInterventionDetails={quantities}
        sdgNumbers={sdgNumbers && sdgNumbers}
        sdgText={sdgText && sdgText}
      />

      <InterventionOverview
        title={'Overview'}
        inputs={quantities}
        name={interventiondetails?.name}
        circlecolor={CategoryColors[category]?.circlecolor}
        textcolor={CategoryColors[category]?.textcolor}
        color={CategoryColors[category]?.color}
      />

      {/* TODO: colors should not directly map with intervention name, need to fix it */}
      <InterventionImpactAndGoals
        title1={'Secondary Impact'}
        title2={'Contribution to Net Zero Goals'}
        goalsProgressPercentage={goalsProgressPercentage}
        feature={key}
        inputs={quantities}
      />
    </div>
  );
};

export default InterventionDetailsSummary;
