/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import PrimaryButton from '../../../../../common/button/Button';
import CustomProgressBar from './customProgressBar';
import notAvailable from '../../../../../../assets/Marketplace/Image not available 1.svg';
import useStyles from '../styles';
import api from '../../../../../../http';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import CustomModal from './customModal';
import numberWithCommas from '../../../../../../utils/numberToComma';
import { Skeleton } from '@material-ui/lab';
import rsToCrore from '../../../../../../utils/rsToCr';
import unit from '../../../../../../utils/unitConfig';
import { useAppMetafireUtilities } from '../../../../../../metafire/hooks/useAppMetafireUtilities';
import useInterventionDetails from '../../../../marketplace/hooks/useInterventionDetailsData';

const formatDateTime = (isoDate) => {
  const date = new Date(isoDate);
  const options = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  const time = date.toLocaleTimeString('en-US', options);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = String(date.getFullYear()).slice(-2);
  return (
    <Typography variant="h7">
      {`${time}`}
      <span style={{ paddingLeft: '5%' }}>{`${day}-${month}-${year}`}</span>
    </Typography>
  );
};

export const InterventionSetCard = ({
  setId,
  name,
  createdAt,
  opex,
  capexIncrease,
  goalCOntribution,
  product,
  reFetchInterventionSets,
  isActive,
  backgrounColor,
  marginleft,
}) => {
  const classes = useStyles();
  const params = useParams();

  const { getValue } = useAppMetafireUtilities();

  const { productDetailsData, fetchData: fetchProductDetailsData } = useInterventionDetails();

  useEffect(() => {
    if (productDetailsData && productDetailsData?.products[0]?.image.trim() !== '') {
      const img = new Image();
      img.src = productDetailsData?.products[0]?.image;
      img.onload = () => setIsImageAccessible(true);
      img.onerror = () => setIsImageAccessible(false);
    }
  }, [productDetailsData]);

  useEffect(() => {
    if (product) fetchProductDetailsData(getValue(product));
  }, [product]);

  const [isImageAccessible, setIsImageAccessible] = useState(false);

  const deleteInterventionSet = async () => {
    await api.deleteInterventionSet({ projectId: params.id, interventionId: params.iid, interventionSetId: setId });
    reFetchInterventionSets();
  };

  const handleApply = async () => {
    await api.updateActiveSet({ projectId: params.id, interventionId: params.iid, activeSetId: setId });
    reFetchInterventionSets();
  };
  const isValidOpex = opex > 0 && capexIncrease > 0;
  const isValidCapex = opex > 0 && capexIncrease > 0;
  return (
    <Paper className={classes.paper} style={{ backgroundColor: backgrounColor, marginLeft: marginleft }}>
      <Grid container item xs={12}>
        <Grid item xs={10}>
          <Typography className={classes.heading}>{name}</Typography>
        </Grid>
        <Grid item xs={2}>
          <CustomModal deleteInterventionSet={deleteInterventionSet} />
        </Grid>
      </Grid>
      <Typography className={classes.time}>{formatDateTime(createdAt)}</Typography>
      <PrimaryButton className={classes.buttonApply} children={isActive ? 'Applied' : 'Apply'} onClick={handleApply} disabled={isActive} />
      <Grid item xs={12} className={classes.CardSpacing}>
        <Typography variant="h6" className={classes.secondaryText}>
          Progress Towards Net Zero Goal
        </Typography>
        {
          <CustomProgressBar
            bgcolor="rgba(242, 141, 20, 1)"
            value={goalCOntribution ? goalCOntribution : <Skeleton variant="text" height={25} animation="wave" />}
            unit={'%'}
            barvalue={goalCOntribution > 100 ? 100 : (goalCOntribution / 100) * 100}
            textColor="rgba(242, 141, 20, 1)"
          />
        }
      </Grid>
      <Grid item xs={12} className={classes.CardSpacing}>
        <Typography variant="h6" className={classes.secondaryText}>
          OPEX Savings (5 Years)
        </Typography>
        {
          <CustomProgressBar
            bgcolor="rgba(116, 222, 251, 1)"
            value={opex ? `${numberWithCommas(rsToCrore('Rs', opex))}` : opex === 0 ? 0 : <Skeleton variant="text" height={25} animation="wave" />}
            unit={unit('cr')}
            barvalue={isValidOpex ? (opex > capexIncrease ? 100 : Math.min((opex / capexIncrease) * 100, 100)) : 0}
            textColor="rgba(116, 222, 251, 1)"
          />
        }
      </Grid>
      <Grid item xs={12} className={classes.CardSpacing}>
        <Typography variant="h6" className={classes.secondaryText}>
          CAPEX Increase
        </Typography>
        {
          <CustomProgressBar
            bgcolor="rgba(255, 206, 147, 1)"
            value={
              capexIncrease ? (
                `${numberWithCommas(rsToCrore('Rs', capexIncrease))}`
              ) : capexIncrease === 0 ? (
                0
              ) : (
                <Skeleton variant="text" height={25} animation="wave" />
              )
            }
            unit={unit('cr')}
            barvalue={isValidCapex ? (capexIncrease > opex ? 100 : (capexIncrease / opex) * 100) : 0}
            textColor="rgba(255, 206, 147, 1)"
          />
        }
        <Grid container item xs={12} className={classes.CardSpacing} style={{ height: '50px' }}>
          <Grid item xs={3}>
            <img className={classes.productImage} src={isImageAccessible ? productDetailsData?.products[0]?.image : notAvailable} alt=".." />
          </Grid>
          <Grid item xs={9}>
            <Typography className={classes.productText}>{productDetailsData && productDetailsData?.products[0]?.sku_name.slice(0, 20)}...</Typography>
            <Typography className={classes.productText}>{productDetailsData && productDetailsData?.products[0]?.brand?.name}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
