/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import CustomBreadCrumbs from '../../../../common/breadcrumbs/CustomBreadCrumbs';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { InterventionSetCard } from './components/interventionSetCard';
import InterventoionDataTable from './components/interventionDataTable';
import useStyles from './styles';
import NoInterventionPage from './components/noInterventionSetsPage';
import api from '../../../../../http';
import AddInterventionSetModal from '../components/AddInterventionSetModal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useInterventionDetailsContext } from '../context/InterventionDetailsV2';

const CompareInterventionSets = () => {
  const classes = useStyles();
  const params = useParams();
  const { intervention, projectIntervention, refetchProjectIntervention } = useInterventionDetailsContext();
  const [openInterventionSetModal, setOpenInterventionSetModal] = useState(false);
  const handleOpen = () => setOpenInterventionSetModal(true);
  const handleClose = () => setOpenInterventionSetModal(false);
  const [sets, setSets] = useState(projectIntervention?.interventionSets);
  const history = useHistory();
  const fetchMetafireData = async () => {
    if (!projectIntervention?.interventionSets?.length) return;
    const data = await Promise.all(
      projectIntervention?.interventionSets?.map(async (interventionSet) => {
        const res = await api.getMetafireDataByInterventionSetId(params.id, interventionSet._id, intervention?.feature);
        return { ...interventionSet, data: res };
      })
    );

    setSets(data);
  };

  useEffect(() => {
    fetchMetafireData();
    if (projectIntervention?.interventionSets) {
      setSets(projectIntervention.interventionSets);
    }
  }, [projectIntervention]);
  return (
    <>
      <Grid container alignItems="center" className={classes.root}>
        <CustomBreadCrumbs
          links={[
            { href: `/projects/${params.id}/interventions`, text: 'Interventions' },
            { text: `${intervention?.name}`, href: `/projects/${params.id}/interventions/${params.iid}` },
          ]}
          stage={<b>Saved Intervention Parameter Sets</b>}
        />
        <Grid container item xs={12} alignItems="center" justify="flex-start" spacing={2} className={classes.root}>
          <Grid xs={0} item>
            <ArrowBackIosIcon
              style={{
                color: '#66E3BE',
                cursor: 'pointer',
                width: '30.41px',
                height: '100%',
              }}
              onClick={() => {
                history.push(`/projects/${params.id}/interventions/${params.iid}`);
              }}
            />
          </Grid>
          <Grid item xs={9}>
            <Typography variant="h3" style={{ marginLeft: '-2%' }}>
              Saved Intervention Parameter Sets
            </Typography>
          </Grid>
        </Grid>

        {!sets?.length && <NoInterventionPage handleOpen={handleOpen} />}

        {sets?.map((set, index) => {
          const backgroundColor = index % 2 === 0 ? 'rgba(245, 245, 245, 1)' : 'rgba(251, 251, 251, 1)';
          const marginleft = index === 0 && '240px';
          const opexData = set.data?.find((item) => Array.isArray(item.tags) && item.tags.includes('Opex'))?.value;
          const capexIncrease = set.data?.find((item) => Array.isArray(item.tags) && item.tags.includes('Capex'))?.value;
          const goalContributionData = set.data?.find((item) => Array.isArray(item.tags) && item.tags.includes('goal-contribution'))?.value;
          const product = set.data?.find((item) => Array.isArray(item.tags) && item.tags.includes('product'));
          return (
            <InterventionSetCard
              setId={set._id}
              name={set.name}
              createdAt={set.createdAt}
              metafireData={set.data || []}
              opex={opexData != null ? opexData * 5 : 0}
              capexIncrease={capexIncrease ?? 0}
              goalCOntribution={goalContributionData != null ? goalContributionData.toFixed(2) : '0.00'}
              product={product}
              reFetchInterventionSets={refetchProjectIntervention}
              isActive={projectIntervention?.activeSetId === set._id}
              backgrounColor={backgroundColor}
              marginleft={marginleft}
            />
          );
        })}

        {sets?.length > 0 && (
          <InterventoionDataTable
            setDescriptions={() => (sets?.length ? sets?.map((item) => item.description || '-') : ['-'])}
            metafireData={sets?.map((set) => set?.data || []).filter(Boolean)}
          />
        )}
      </Grid>
      {openInterventionSetModal && (
        <AddInterventionSetModal open={openInterventionSetModal} handleClose={handleClose} projectId={params.id} interventionId={intervention._id} />
      )}
    </>
  );
};

export default CompareInterventionSets;
