import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import useScreenType from '../../../hooks/checkScreenType';
import { Box, Grid, makeStyles, withWidth } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import notAvailable from '../../../../assets/Marketplace/Image not available 1.svg';
import Links from './Typography/Links';
import UIPC_logo from '../../../../assets/Marketplace/EcoLabels/UIPC-I.svg';
import IGBC_logo from '../../../../assets/Marketplace/EcoLabels/IGBC.svg';
import Griha_logo from '../../../../assets/Marketplace/EcoLabels/Griha.svg';
import GreenPro_logo from '../../../../assets/Marketplace/EcoLabels/GreenPro.svg'; // exceeds 500
import Green_Label_logo from '../../../../assets/Marketplace/EcoLabels/Green Label.svg';
import GreenCo_logo from '../../../../assets/Marketplace/EcoLabels/GreenCo.svg';
import Green_Assure_logo from '../../../../assets/Marketplace/EcoLabels/GreenAssure.svg';
import ECBC_logo from '../../../../assets/Marketplace/certificationfallback.svg';
import LEED_logo from '../../../../assets/Marketplace/EcoLabels/Leed.svg';
import PrimaryButton from '../../../common/button/Button';
import CustomAlert from '../../interventions/interventionDetails/marketplace-link/CustomAlert';
import CustomCheckBox from '../../../common/checkbox/CustomCheckBox';
import axios from 'axios';
import AddIcon from '@material-ui/icons/Add';
import swal from 'sweetalert';
import { MarketplaceColors } from '../config';
import { MetafireInterventions } from '../../../../metafire/utils/types';
import { addShortlistedProduct } from '../../../../http/projectIntervention';

const useStyles = makeStyles((theme) => ({
  cardDesktop: {
    width: '220px',
    height: '340px',
    // margin: '10px',
    marginRight: '2%',
    marginBottom: '2%',
    boxShadow: 'none',
    border: '1px solid #00000033',
    borderRadius: '10px',
  },
  cardDesktopSelected: {
    width: '220px',
    height: '340px',
    // margin: '10px',
    marginRight: '2%',
    marginBottom: '2%',
    boxShadow: 'none',
    border: '2px solid rgba(38, 224, 163, 1)',
    borderRadius: '10px',
  },
  cardMobile: {
    width: '183px',
    margin: '10px',
    boxShadow: 'none',
    border: '1px solid #00000033',
    borderRadius: '10px',
  },
  cardMobileSelected: {
    width: '183px',
    margin: '10px',
    boxShadow: 'none',
    border: '2px solid rgba(38, 224, 163, 1)',
    borderRadius: '10px',
  },
  media: {
    margin: 10,
    height: 150,
    backgroundSize: 'contain',
  },
  mediamobile: {
    height: 100,
    margin: 10,
    backgroundSize: 'contain',
  },
  boxStyle: {
    width: '40px',
    height: '20px',
    borderRadius: '4px',
    border: '1px solid rgba(38, 224, 163, 1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 150,
    right: 10,
  },
  name: {
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '19px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  subname: {
    fontFamily: 'Lato',
    fontSize: '10px',
    fontWeight: '400',
    lineHeight: '12px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  description: {
    fontFamily: 'Lato',
    fontSize: '12px',
    fontWeight: '300',
    lineHeight: '20px',
    letterSpacing: '0.5px',
    textAlign: 'left',
  },
  icon: {
    textAlign: 'left',
    paddingTop: '8%',
  },
  bttongridstyle: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  addIcon: {
    fontSize: '25px',
    backgroundColor: 'rgba(26, 184, 232, 1)',
    color: 'rgba(255, 255, 255, 1)',
    margin: 10,
    borderRadius: '5px',
  },
  buttondivstyle: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1,
    borderRadius: '7px',
    cursor: 'pointer',
  },
  compareSelectCheckbox: {
    position: 'absolute',
    top: '12px',
    right: '6px',
    zIndex: 1,
  },
  typostyle: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    color: 'rgba(26, 184, 232, 1)',
    fontWeight: 700,
    marginRight: '5px',
  },
  defaulttypostyle: {
    display: 'flex',
    alignItems: 'center',
    // width: "100%",
    fontSize: '8px',
    lineHeight: '20px',
    color: 'rgba(38, 224, 163, 1)',
    fontWeight: 700,
    // marginRight: "5px"
  },
  defaultText: {
    fontFamily: 'Lato',
    fontSize: '11px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.7142857313156128px',
    textAlign: 'center',
  },
}));

export function getIcon(name) {
  let icons = [
    { name: 'ECBC', icon: ECBC_logo },
    { name: 'Green Assure', icon: Green_Assure_logo },
    { name: 'GreenCo', icon: GreenCo_logo },
    { name: 'Green Label', icon: Green_Label_logo },
    { name: 'GreenPro', icon: GreenPro_logo },
    { name: 'Griha', icon: Griha_logo },
    { name: 'IGBC', icon: IGBC_logo },
    { name: 'LEED', icon: LEED_logo },
    { name: 'UIPC-I', icon: UIPC_logo },
  ];
  for (let i = 0; i < icons.length; i++) {
    if (icons[i].name === name) {
      return icons[i].icon;
    }
  }
}

function ProductCard(props) {
  const classes = useStyles();
  const {
    width,
    productImage,
    productName,
    productBrandName,
    productShortDescription,
    productCategoryID,
    productID,
    identifier,
    ecolabels,
    source,
    section,
    mappingid,
    getInterventionMappingData,
    setShortlistedProducts,
    activeSandbox,
    dirtyValue,
    projectid,
    handleRefreshdetails,
    handleBackdropOpen,
    productIdeantifier,
    interventionname,
    product,
    defaultProduct,
    selectedProduct,
    dataReload,
    setDataReload,
    isCompareModeEnabled,
    setProductSelectedForComparison,
    productSelectedForComparison,
  } = props;
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const projectId = query.get('projectId');
  const [open, setOpen] = React.useState(false);
  const { isMobile } = useScreenType(width);
  const [isImageAccessible, setIsImageAccessible] = useState(false);
  const [isProductSelectedForComparison, setIsProductSelectedForComparison] = useState(productSelectedForComparison?.includes(productID) ? true : false);

  useEffect(() => {
    if (productImage && productImage.trim() !== '') {
      const img = new Image();
      img.src = productImage;
      img.onload = () => setIsImageAccessible(true);
      img.onerror = () => setIsImageAccessible(false);
    }
  }, [productImage]);

  /*for the new intervention link*/
  const [focusbutton, setFocusbutton] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  function updateOrCreateProductIdentifier(data, newValue) {
    let found = false;
    let ideantifier = productIdeantifier();
    for (let obj of data) {
      if (ideantifier !== 'NA') {
        if (obj.id === ideantifier) {
          obj.value = newValue;
          found = true;
          break;
        }
      }
    }
    if (!found) {
      if (ideantifier !== 'NA') {
        const newObject = {
          id: ideantifier,
          value: newValue,
        };
        data.push(newObject);
      }
    }
    return data;
  }

  function updateOrCreateallConditions(data, newValue, tag) {
    let found = false;
    // let ideantifier = productIdeantifier()
    for (let obj of data) {
      if (obj.id === tag) {
        obj.value = newValue;
        found = true;
        break;
      }
    }
    if (!found) {
      const newObject = {
        id: tag,
        value: newValue,
      };
      data.push(newObject);
    }
    return data;
  }

  const handleUpdateSandbox = (values) => {
    const body = { type: 'dirty', values: values };
    axios
      .put(`/projectv2/${projectid}/interventions/${mappingid}/sandboxes/${activeSandbox}`, body)
      .then((res) => {
        if (res && res.data.data) {
          handleRefreshdetails();
          handleBackdropOpen();
        }
      })
      .catch((err) => console.log(err));
  };

  const handleRemove = () => {
    let conditionTag;
    if (interventionname === MetafireInterventions.hwmApartment) {
      if (product?.subcategory?.name === 'Solar Water Heaters') {
        if (product?.filters?.['System types'][0] === 'Centralized Solar Heater') {
          conditionTag = 'hwmApartment.swhcentral';
          updateOrCreateallConditions(dirtyValue, null, 'hwmApartment.swhcentral');
        }
        if (product?.filters?.['System types'][0] === 'Individual Solar Heater' && product?.filters?.Variant[0] === 'ETC') {
          conditionTag = 'hwmApartment.swhIndividualEtc';
          updateOrCreateallConditions(dirtyValue, null, 'hwmApartment.swhIndividualEtc');
        }
        if (product?.filters?.['System types'][0] === 'Individual Solar Heater' && product?.filters?.Variant[0] === 'FPC') {
          conditionTag = 'hwmApartment.swhindividualFpc';
          updateOrCreateallConditions(dirtyValue, null, 'hwmApartment.swhindividualFpc');
        }
      } else if (product?.subcategory?.name === 'Heat Pumps') {
        if (product?.filters?.['System types'][0] === 'Swimming Pool Heat Pumps') {
          conditionTag = 'hwmApartment.hpcommercial';
          updateOrCreateallConditions(dirtyValue, null, 'hwmApartment.hpcommercial');
        }
        if (product?.filters?.['System types'][0] === 'Domestic Heat Pumps') {
          conditionTag = 'hwmApartment.hpDomestic';
          updateOrCreateallConditions(dirtyValue, null, 'hwmApartment.hpDomestic');
        }
      } else if (product?.subcategory?.name === 'Hybrid system') {
        conditionTag = 'hwmApartment.hybrid';
        updateOrCreateallConditions(dirtyValue, null, 'hwmApartment.hybrid');
      }
    }
    if (interventionname === MetafireInterventions.hwmClubhouse) {
      if (product?.subcategory?.name === 'Solar Water Heaters') {
        if (product?.filters?.['System types'][0] === 'Centralized Solar Heater') {
          conditionTag = 'hwmClubHouse.swhcentral';
          updateOrCreateallConditions(dirtyValue, null, 'hwmClubHouse.swhcentral');
        }
        if (product?.filters?.['System types'][0] === 'Individual Solar Heater' && product?.filters?.Variant[0] === 'ETC') {
          conditionTag = 'hwmClubHouse.swhIndividualEtc';
          updateOrCreateallConditions(dirtyValue, null, 'hwmClubHouse.swhIndividualEtc');
        }
        if (product?.filters?.['System types'][0] === 'Individual Solar Heater' && product?.filters?.Variant[0] === 'FPC') {
          conditionTag = 'hwmClubHouse.swhindividualFpc';
          updateOrCreateallConditions(dirtyValue, null, 'hwmClubHouse.swhindividualFpc');
        }
      } else if (product?.subcategory?.name === 'Heat Pumps') {
        if (product?.filters?.['System types'][0] === 'Swimming Pool Heat Pumps') {
          conditionTag = 'hwmClubHouse.hpcommercial';
          updateOrCreateallConditions(dirtyValue, null, 'hwmClubHouse.hpcommercial');
        }
        if (product?.filters?.['System types'][0] === 'Domestic Heat Pumps') {
          conditionTag = 'hwmClubHouse.hpDomestic';
          updateOrCreateallConditions(dirtyValue, null, 'hwmClubHouse.hpDomestic');
        }
      }
    }
    if (section === 'shortlisted') {
      let body = {
        productId: identifier,
      };
      axios
        .put(`/ProjectInterventionMapping/intervention/${mappingid}?key=removeSelectedProductFromShortlist`, body)
        .then((res) => {
          getInterventionMappingData();
          setDataReload(!dataReload);
          setOpen(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      let updatedValue = updateOrCreateProductIdentifier(dirtyValue, null);
      handleUpdateSandbox(updatedValue);
      axios
        .put(`/ProjectInterventionMapping/intervention/${mappingid}?key=removeSelectedProduct`)
        .then((response) => {
          getInterventionMappingData();
          setDataReload(!dataReload);
          setOpen(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleSelectVendor = () => {
    let conditionTag;
    if (interventionname === MetafireInterventions.hwmApartment) {
      if (product?.subcategory?.name === 'Solar Water Heaters') {
        if (product?.filters?.['System types'][0] === 'Centralized Solar Heater') {
          conditionTag = 'hwmApartment.swhcentral';
          updateOrCreateallConditions(dirtyValue, identifier, 'hwmApartment.swhcentral');
        }
        if (product?.filters?.['System types'][0] === 'Individual Solar Heater' && product?.filters?.Variant[0] === 'ETC') {
          conditionTag = 'hwmApartment.swhIndividualEtc';
          updateOrCreateallConditions(dirtyValue, identifier, 'hwmApartment.swhIndividualEtc');
        }
        if (product?.filters?.['System types'][0] === 'Individual Solar Heater' && product?.filters?.Variant[0] === 'FPC') {
          conditionTag = 'hwmApartment.swhindividualFpc';
          updateOrCreateallConditions(dirtyValue, identifier, 'hwmApartment.swhindividualFpc');
        }
      } else if (product?.subcategory?.name === 'Heat Pumps') {
        if (product?.filters?.['System types'][0] === 'Swimming Pool Heat Pumps') {
          conditionTag = 'hwmApartment.hpcommercial';
          updateOrCreateallConditions(dirtyValue, identifier, 'hwmApartment.hpcommercial');
        }
        if (product?.filters?.['System types'][0] === 'Domestic Heat Pumps') {
          conditionTag = 'hwmApartment.hpDomestic';
          updateOrCreateallConditions(dirtyValue, identifier, 'hwmApartment.hpDomestic');
        }
      } else if (product?.subcategory?.name === 'Hybrid system') {
        conditionTag = 'hwmApartment.hybrid';
        updateOrCreateallConditions(dirtyValue, identifier, 'hwmApartment.hybrid');
      }
    }
    if (section === 'shortlisted') {
      let updatedValue = updateOrCreateProductIdentifier(dirtyValue, identifier);
      handleUpdateSandbox(updatedValue);
      // setDataReload(!dataReload)
    } else {
      let updatedValue = updateOrCreateProductIdentifier(dirtyValue, null);
      handleUpdateSandbox(updatedValue);
      axios
        .put(`/ProjectInterventionMapping/intervention/${mappingid}?key=removeSelectedProduct`)
        .then((response) => {
          getInterventionMappingData();
          setDataReload(!dataReload);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleButtonMouseEnter = () => {
    setFocusbutton(true);
  };
  const handleButtonMouseLeave = () => {
    setFocusbutton(false);
  };
  const handleAddIntervention = () => {
    const updatedShortlistedProducts = [identifier];
    const body = { projectId: projectId, interventionId: mappingid, shortlistedProduct: updatedShortlistedProducts };
    addShortlistedProduct(body)
      .then((response) => {
        if (response.success) swal({ icon: 'success', title: 'Successfully added the product to the Intervention' });
      })
      .catch((error) => {
        console.error('Error updating shortlisted products:', error);
      });
  };

  const getCardStyle = () => {
    // console.log(selectedProduct, identifier, 'selectedProduct')
    if (isMobile) {
      return selectedProduct !== undefined ? (selectedProduct === identifier ? classes.cardMobileSelected : classes.cardMobile) : classes.cardMobile;
    } else {
      return selectedProduct !== undefined ? (selectedProduct === identifier ? classes.cardDesktopSelected : classes.cardDesktop) : classes.cardDesktop;
    }
  };
  useEffect(() => {
    if (!isCompareModeEnabled) {
      setIsProductSelectedForComparison(false);
    }
  }, [isCompareModeEnabled]);
  const addProductSelectedForComparison = () => {
    if (productSelectedForComparison?.includes(productID)) {
      const updatedProducts = productSelectedForComparison.filter((id) => id !== productID);
      setProductSelectedForComparison(updatedProducts);
    } else {
      setProductSelectedForComparison([...productSelectedForComparison, productID]);
    }
  };

  return (
    <Card
      fullwidth
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={getCardStyle()}
      style={{ position: 'relative', overflow: 'visible' }}
    >
      {source !== 'intervention' && (
        <div
          onClick={() => {
            if ((isCompareModeEnabled && productSelectedForComparison.length < 5) || isProductSelectedForComparison) {
              addProductSelectedForComparison();
              setIsProductSelectedForComparison(!isProductSelectedForComparison);
            }
          }}
          style={{ cursor: 'pointer' }}
        >
          <a
            target="_blank"
            rel="noreferrer"
            onclick={() => {}}
            href={
              mappingid
                ? `/marketplace/categories/${productCategoryID}/products/${productID}?mappingID=${mappingid}`
                : `/marketplace/categories/${productCategoryID}/products/${productID}`
            }
            style={{ pointerEvents: isCompareModeEnabled ? 'none' : '', textDecoration: 'none', color: 'black' }}
          >
            <CardActionArea>
              <CardMedia
                className={isMobile ? classes.mediamobile : classes.media}
                image={isImageAccessible ? productImage : notAvailable}
                title={productName}
                onClick={() => {}}
              />
              {/* <CardMedia className={isMobile ? classes.mediamobile : classes.media} image={(productImage) => GetImage(productImage)} title={productName} /> */}
              <CardContent style={{ paddingLeft: '15px' }}>
                <Links
                  href={`/marketplace/categories/${productCategoryID}/products/${productID}`}
                  variant="string"
                  tag={`${productName.length > 21 ? `${productName.slice(0, 20)}...` : productName}`}
                  isBold={false}
                  className={classes.name}
                  color="inherit"
                />
                <Typography gutterBottom className={classes.subname} style={{ paddingTop: '5%', textAlign: 'left' }}>
                  {productBrandName}
                </Typography>
                <Typography
                  className={classes.description}
                  style={{
                    color: '#9E9E9E',
                    paddingTop: '5%',
                    height: '40px',
                  }}
                >
                  {productShortDescription.length > 50 ? `${productShortDescription.slice(0, 50)}...` : productShortDescription}
                </Typography>
                <Typography className={classes.icon}>
                  {ecolabels?.map((name) => (getIcon(name) ? <img key={name} src={getIcon(name)} alt="..." height={23} width={23} /> : null))}
                </Typography>
              </CardContent>
            </CardActionArea>
          </a>
        </div>
      )}
      {source === 'intervention' && section !== 'shortlisted' && (
        <a
          target="_blank"
          onclick="return false;"
          rel="noreferrer"
          href={
            mappingid
              ? `/marketplace/categories/${productCategoryID}/products/${productID}?mappingID=${mappingid}`
              : `/marketplace/categories/${productCategoryID}/products/${productID}`
          }
          style={{ textDecoration: 'none', color: 'black' }}
        >
          <CardActionArea>
            <CardMedia
              // style={{ paddingTop: '10%' }}
              className={isMobile ? classes.mediamobile : classes.media}
              image={isImageAccessible ? productImage : notAvailable}
              title={productName}
            />
            {defaultProduct === identifier && (
              <Box className={classes.boxStyle}>
                <Typography className={classes.defaulttypostyle} style={{ alignItems: 'center', justifyContent: 'center' }}>
                  Default
                </Typography>
              </Box>
            )}
            {/* <CardMedia className={isMobile ? classes.mediamobile : classes.media} image={(productImage) => GetImage(productImage)} title={productName} /> */}
            <CardContent style={{ paddingLeft: '15px' }}>
              <Links
                href={`/marketplace/categories/${productCategoryID}/products/${productID}`}
                variant="string"
                tag={`${productName.length > 21 ? `${productName.slice(0, 20)}...` : productName}`}
                isBold={false}
                className={classes.name}
                color="inherit"
              />
              <Typography gutterBottom className={classes.subname} style={{ paddingTop: '5%', textAlign: 'left' }}>
                {productBrandName}
              </Typography>
              <Typography
                className={classes.description}
                style={{
                  color: '#9E9E9E',
                  paddingTop: '5%',
                  height: '40px',
                }}
              >
                {productShortDescription.length > 50 ? `${productShortDescription.slice(0, 50)}...` : productShortDescription}
              </Typography>
              <Typography className={classes.icon}>
                {ecolabels?.map((name) => (getIcon(name) ? <img key={name} src={getIcon(name)} alt="..." height={23} width={23} /> : null))}
              </Typography>
            </CardContent>
          </CardActionArea>
        </a>
      )}
      {!isHover && source === 'intervention' && section === 'shortlisted' && (
        <a
          target="_blank"
          onclick="return false;"
          rel="noreferrer"
          href={`/marketplace/categories/${productCategoryID}/products/${productID}`}
          style={{ textDecoration: 'none', color: 'black' }}
        >
          <CardActionArea>
            <CardMedia
              // style={{ paddingTop: '10%' }}
              className={isMobile ? classes.mediamobile : classes.media}
              image={isImageAccessible ? productImage : notAvailable}
              title={productName}
            />
            {defaultProduct === identifier && (
              <Box className={classes.boxStyle}>
                <Typography className={classes.defaulttypostyle} style={{ alignItems: 'center', justifyContent: 'center' }}>
                  Default
                </Typography>
              </Box>
            )}
            <CardContent style={{ paddingLeft: '15px' }}>
              <Links
                href={`/marketplace/categories/${productCategoryID}/products/${productID}`}
                variant="string"
                tag={`${productName.length > 21 ? `${productName.slice(0, 20)}...` : productName}`}
                isBold={false}
                className={classes.name}
                color="inherit"
              />
              <Typography gutterBottom className={classes.subname} style={{ paddingTop: '5%', textAlign: 'left' }}>
                {productBrandName}
              </Typography>
              <Typography
                className={classes.description}
                style={{
                  color: '#9E9E9E',
                  paddingTop: '5%',
                  height: '40px',
                }}
              >
                {productShortDescription.length > 50 ? `${productShortDescription.slice(0, 50)}...` : productShortDescription}
              </Typography>
              <Typography className={classes.icon}>
                {ecolabels?.map((name) => (getIcon(name) ? <img key={name} src={getIcon(name)} alt="..." height={23} width={23} /> : null))}
              </Typography>
            </CardContent>
          </CardActionArea>
        </a>
      )}
      {isHover && source === 'intervention' && section === 'shortlisted' && (
        <CardContent style={{ paddingLeft: '15px' }}>
          <Typography gutterBottom className={classes.name} style={{ paddingTop: '5%', textAlign: 'left' }}>
            {`${productName.length > 21 ? `${productName.slice(0, 20)}...` : productName}`}
          </Typography>
          <Typography gutterBottom className={classes.subname} style={{ paddingTop: '5%', textAlign: 'left' }}>
            {productBrandName}
          </Typography>
          {selectedProduct !== identifier && defaultProduct !== identifier && (
            <>
              <Grid container item xs={12} className={classes.bttongridstyle} style={{ paddingTop: '80%' }}>
                <PrimaryButton
                  onClick={handleSelectVendor}
                  children={section === 'shortlisted' ? 'select product' : 'deselect product'}
                  style={{
                    width: '90%',
                    boxShadow: 'none',
                  }}
                />
              </Grid>
              <Grid container item xs={12} className={classes.bttongridstyle}>
                {<CustomAlert handleRemove={handleRemove} open={open} setOpen={setOpen} />}
              </Grid>
            </>
          )}
          {defaultProduct === identifier && selectedProduct !== identifier && (
            <>
              <Typography
                className={classes.defaultText}
                style={{
                  paddingTop: '80%',
                  width: '90%',
                  textAlign: 'center',
                  paddingLeft: '10%',
                }}
              >
                DEFAULT PRODUCT CANNOT BE REMOVED
              </Typography>
              <Grid container item xs={12} className={classes.bttongridstyle}>
                <PrimaryButton
                  onClick={handleSelectVendor}
                  children={section === 'shortlisted' ? 'select product' : 'deselect product'}
                  style={{
                    width: '90%',
                    boxShadow: 'none',
                  }}
                />
              </Grid>
            </>
          )}
          {defaultProduct === identifier && selectedProduct === identifier && (
            <>
              <Typography
                className={classes.defaultText}
                style={{
                  paddingTop: '80%',
                  width: '90%',
                  textAlign: 'center',
                  paddingLeft: '10%',
                }}
              >
                DEFAULT PRODUCT CANNOT BE REMOVED
              </Typography>
              <Grid container item xs={12} className={classes.bttongridstyle}>
                <PrimaryButton
                  onClick={handleSelectVendor}
                  children={section === 'shortlisted' ? 'select product' : 'deselect product'}
                  style={{
                    width: '90%',
                    boxShadow: 'none',
                  }}
                />
              </Grid>
            </>
          )}
          {selectedProduct === identifier && defaultProduct !== identifier && (
            <>
              <Grid container item xs={12} className={classes.bttongridstyle} style={{ paddingTop: '80%' }}>
                <PrimaryButton
                  onClick={handleSelectVendor}
                  children={section === 'shortlisted' ? 'select product' : 'deselect product'}
                  style={{
                    width: '90%',
                    boxShadow: 'none',
                  }}
                  disabled={true}
                  backgroundColor="linear-gradient(90deg, #1FDEDB80 0%, #42E19880 50%, #42E19880 50%)"
                  textcolor="rgba(255, 255, 255, 1)"
                />
              </Grid>
              <Grid container item xs={12} className={classes.bttongridstyle}>
                {defaultProduct !== identifier && <CustomAlert handleRemove={handleRemove} open={open} setOpen={setOpen} disabled={true} />}
              </Grid>
            </>
          )}
        </CardContent>
      )}
      {isHover && source !== undefined && source !== 'intervention' && !isCompareModeEnabled && (
        <div
          onMouseEnter={handleButtonMouseEnter}
          onMouseLeave={handleButtonMouseLeave}
          className={classes.buttondivstyle}
          style={{
            background: focusbutton ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 1)',
          }}
          onClick={handleAddIntervention}
        >
          <Typography className={classes.typostyle}>
            <AddIcon className={classes.addIcon} />
            {focusbutton && `ADD TO INTERVENTION`}
          </Typography>
        </div>
      )}
      {isCompareModeEnabled && (
        <div
          onMouseEnter={handleButtonMouseEnter}
          onMouseLeave={handleButtonMouseLeave}
          className={classes.compareSelectCheckbox}
          style={{
            height: '20px',
            width: '20px',
          }}
          onClick={handleAddIntervention}
        >
          <CustomCheckBox
            style={{
              transform: 'scale(1.8)',
              backgroundColor: 'white',
              padding: '1px',
            }}
            borderSize={4}
            color={MarketplaceColors.checkboxColor}
            checkedcolor={MarketplaceColors.checkboxColor}
            checkState={isProductSelectedForComparison}
            handleClick={() => {
              if (productSelectedForComparison.length < 5 || isProductSelectedForComparison) {
                addProductSelectedForComparison();
                setIsProductSelectedForComparison(!isProductSelectedForComparison);
              }
            }}
          />
        </div>
      )}
    </Card>
  );
}

export default withWidth()(ProductCard);
