/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { Grid, Typography, FormControl, Select, MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import swal from 'sweetalert';
import axios from '../../../../config/Axios';
import PrimaryButton from '../../../common/button/Button';
import HeadAndBackIcon from '../../../common/header/HeadAndBackIcon';
import PreferenceUnits from '../common/components/PreferenceUnits';
import LocationBox from '../common/components/Location';
import SimplePIPS from '../common/components/SimplePIPS';
import { SomethingChanged } from '../EditProject';
import Fields from '../../../common/textfield/Fields';
import OrganisationSelect from './Components/OrganisationSelect';
import TooglebuttonGroupForCreateProject from '../../createproject/components/TooglebuttonGroupForCreateProject';
import { makeStyles } from '@material-ui/core/styles';
import { useAppApiRequest } from '../../../../hooks/useAppApiRequest';
import useAppPIPScheme from '../hooks/useAppPIPScheme';
import useAppProjectPIPMapping from '../hooks/useAppProjectPIPMapping';
import useAppProjectDetails from '../hooks/useAppProjectDetails';
import ApiError from '../../../common/Error/ApiError';
import { Cities } from '../../../../utils/cities';
import { checkErrorForZeros } from '../../createproject/RequiredFields';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 155,
    '& > *': {
      borderRadius: '50px',
      height: '7vh',
    },
  },
}));

export const EditProjectBasicPIP = (props) => {
  const { type, next, name } = props;
  const history = useHistory();
  const { id } = useParams();
  const classes = useStyles();

  // Location Service States
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState(false);
  const [newloc, setNewloc] = useState({});
  const [value, setValue] = React.useState(options[0]);
  const [inputchange, setInputChange] = useState('');
  const [locationInput, setLocationInput] = useState('');
  const [cityOptions, setCityOptions] = useState([]);
  const { somethingChanged, handlSetSomethingChanged } = useContext(SomethingChanged);

  // API Requests
  const { data: pips } = useAppApiRequest(`/pipschemev2?required=true`, { method: 'GET' });
  const requiredpips = pips?.data?.data;
  const { data: projectData } = useAppProjectDetails(id);

  const {
    get: { form, city, projectPIP, isError },
    set: { handlePIPValueUpdate, handleUpdateForm },
  } = useAppProjectPIPMapping(id, {
    name: null,
    organisation: null,
    category: null,
    subcategory: null,
    location: {},
    city: null,
  });

  // PIP Scheme Data
  const { data } = useAppPIPScheme(type);
  const unitPreferencesPIP = data?.filter((field) => field.name === 'project__pi_unitPreferenceForArea');

  // PIP Configuration
  const basicPIPs = ['project__pi_city', 'project__pi_siteArea', 'project__pi_builtUpArea'];
  const villaAdditionalPips = ['project__pi_availableVillaRooftopArea'];
  const apartmentAdditionalPips = ['project__pi_rooftopArea'];

  const simplePIPs = data?.filter((field) => basicPIPs.includes(field.name))?.sort((a, b) => basicPIPs.indexOf(a.name) - basicPIPs.indexOf(b.name)) || [];
  const villaData = data?.filter((field) => villaAdditionalPips.includes(field.name)) || [];
  const apartmentData = data?.filter((field) => apartmentAdditionalPips.includes(field.name)) || [];

  const simplePIPsBySelection = form?.subcategory === 'villa' ? [...simplePIPs, ...villaData] : [...simplePIPs, ...apartmentData];

  const defaultValue = { id: form.organisation, label: form.location.name, fieldLabel: 'Project Location*' };
  useEffect(() => {
    if (projectData) {
      handleUpdateForm('name', projectData?.name);
      handleUpdateForm('category', projectData?.category);
      handleUpdateForm('subcategory', projectData?.subcategory);
      handleUpdateForm('organisation', projectData?.organisation);
      handleUpdateForm('location', projectData?.location);
    }
  }, [projectData]);

  useEffect(() => {
    if (city) {
      handleUpdateForm('city', city?.value);
    }
  }, [city]);

  useEffect(() => {
    (async () => {
      if (props.saveAfterSomethingChanged === true && next) {
        await handleSave(next);
        props.toogleShowModal(false);
        props.SETNEXT('');
        props.setFalseAfterSomethingChanged();
      }
    })();
  }, [props.saveAfterSomethingChanged, next]);

  // Navigation Handlers
  const handleNext = (next) => {
    if (next && next === 'goals') {
      history.push(`/projects/${id}/goals`);
      return;
    }

    if (somethingChanged) {
      props.toogleShowModal(true);
      props.SETNEXT(next);
      return;
    }

    if (next === 'insights') history.push(`/projects/${id}/insights`);
    else if (next === 'projects') history.push('/projects');
    else history.push(`/projects/${id}/edit?type=${next}`);
  };
  useEffect(() => {
    let handler;
    if (locationInput && locationInput?.length) {
      handler = setTimeout(() => {
        setLoading(true);
        axios.get(`/location?location=${locationInput}`).then((resp) => {
          if (resp && resp.data && resp.data.data) setCityOptions([...resp.data.data.data]);
        });
        setOpen(true);
        setLoading(false);
      }, 250);
    }
    return () => {
      clearTimeout(handler);
    };
  }, [locationInput]);

  const handleGoBack = (type) => {
    if (type === 'basic') history.push(`/projects/${id}/insights`);
    else history.push(`/projects/${id}/edit?type=basic`);
  };
  const handleOpen = () => setOpen(true); // Handle opening of the autocomplete
  const handleClose = () => setOpen(false);

  useEffect(async () => {
    if (type === 'basic' && newloc && newloc.placeId) {
      const loc = await getLocation();
      if (loc) {
        const locationResponse = loc.data?.data?.data;
        handleUpdateForm('location', {
          name: locationResponse.name,
          type: 'Point',
          coordinates: [locationResponse.coordinates.lat, locationResponse.coordinates.long],
        });
      }
    }
  }, [newloc]);
  const handleLocationChange = (event, value) => {
    setLocationInput(value);
  };

  const updateProjectMapping = async () => {
    return axios.put(`/ProjectPIPMapping`, {
      projectId: id,
      values: Object.entries(form?.pipvalues).map(([key, value]) => ({
        PIPScheme: key,
        value,
      })),
    });
  };

  const updateProjectV2Basics = async () => {
    return await axios.put(`/projectv2/${id}/basics`, {
      name: form?.name,
      category: form?.category,
      subcategory: form?.subcategory,
      organisation: form?.organisation,
      location: form?.location,
    });
  };
  const getOptionLabel = (option) => option.label || '';

  const getLocation = async () => {
    return await axios.get(`/location/place?id=${newloc.placeId}`);
  };
  const handleSave = async (next) => {
    await updateProjectMapping();
    await updateProjectV2Basics();
    props.toogleShowModal(false);
    handlSetSomethingChanged(false);
    props.setFalseAfterSomethingChanged();
    props.SETNEXT('');

    swal({ icon: 'success', title: 'Data Saved' });

    if (next === 'insights') history.push(`/projects/${id}/insights`);
    else if (next === 'projects') history.push('/projects');
    else history.push(`/projects/${id}/edit?type=${next}`);
  };

  const handleCityChange = (value) => {
    const cityschema = requiredpips?.data?.find((ele) => ele?.name === 'project__pi_city');
    handleUpdateForm('city', value);
    handlePIPValueUpdate(cityschema?._id, value);
  };

  if (isError) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', paddingTop: 24, width: '100%' }}>
        <ApiError />
      </div>
    );
  }

  return (
    <>
      <Grid item container justifyContent="left" direction="column" xs={12} style={{ marginTop: '3%', marginLeft: '1%' }}>
        <HeadAndBackIcon
          heading={`${name && name} ${projectData?.name ? projectData?.name : ''}`}
          handleClick={() => handleGoBack(type)}
          visitGoals={false}
          headingVariant="h5"
          headingStyle={{ fontSize: '20px', fontWeight: 'bold' }}
          showArrow={false}
        />
      </Grid>

      {type === 'basic' && (
        <Typography variant="h6" color="textSecondary" style={{ marginLeft: '2%' }}>
          *All fields are mandatory
        </Typography>
      )}

      {/* Project Name */}
      <Grid container direction="row" item xs={12} style={{ paddingLeft: '2%', marginTop: '2%' }}>
        <Grid item xs={12} Style={{ marginTop: '3%' }}>
          <Typography variant="h5">Project Name*</Typography>
        </Grid>
        <Grid item xs={5}>
          <Fields id="standard-error-helper-text" value={form?.name} onChange={(event) => handleUpdateForm('name', event.target.value)} fullWidth required />
        </Grid>
      </Grid>

      {/* Organisation */}
      <Grid container direction="row" item xs={12} style={{ paddingLeft: '2%', marginTop: '2%' }}>
        <Grid container item xs={12} Style={{ marginTop: '3%' }}>
          <Typography variant="h5">Enter Organisation Name*</Typography>
        </Grid>
        <Grid item xs={2}>
          <OrganisationSelect selectedOrganisationID={form?.organisation || ''} handleChange={(value) => handleUpdateForm('organisation', value)} />
        </Grid>
      </Grid>

      {/* Category Selection */}
      <Grid container direction="row" item xs={12} style={{ paddingLeft: '2%' }}>
        <TooglebuttonGroupForCreateProject
          category={form?.category}
          handleChange={(event, value) => value && handleUpdateForm('category', value)}
          subCategory={form?.subcategory}
          setSubCategory={(value) => handleUpdateForm('subcategory', value)}
          requiredpips={requiredpips}
          appendChanged={(id, value) => handlePIPValueUpdate(id, value)}
          pipValues={form?.pipvalues}
          disableSubcategorySelection={true}
          unit={form?.unitPIPValue}
          projectPIP={projectPIP}
        />
      </Grid>
      {/* City Selection */}
      {type === 'basic' &&
        simplePIPsBySelection &&
        projectPIP?.length !== 0 &&
        projectData &&
        city &&
        simplePIPsBySelection.map((field, index) => {
          if (field.name === 'project__pi_city') {
            return (
              <>
                <Grid key={index} style={{ paddingLeft: '2%', marginTop: '3%' }}>
                  <Grid item xs={12} className={classes.spacing}>
                    <Typography variant="h5">Enter Project City*</Typography>
                  </Grid>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <Select value={form?.city} onChange={(e) => handleCityChange(e.target.value)}>
                      {Cities.map((city) => (
                        <MenuItem key={city} value={city} name={city}>
                          {city}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid style={{ marginLeft: '15px' }} item xs={12}>
                  <LocationBox
                    onInputChange={handleLocationChange}
                    field={form?.location}
                    defaultValue={defaultValue}
                    loading={loading}
                    options={cityOptions}
                    setNewloc={setNewloc}
                    onOpen={handleOpen}
                    onClose={handleClose}
                    getOptionLabel={getOptionLabel}
                  />
                </Grid>
              </>
            );
          }
          return null;
        })}

      {/* Unit Preferences */}
      {type === 'basic' &&
        unitPreferencesPIP &&
        projectPIP?.length !== 0 &&
        unitPreferencesPIP.map((field, index) => (
          <Grid container key={index} style={{ marginBottom: '2%' }}>
            <PreferenceUnits
              field={field}
              type={type}
              index={index}
              radioButtonValue={form?.unitPIPValue}
              onChange={(e) => {
                handleUpdateForm('unitPIPValue', e.target.value);
                handlePIPValueUpdate(field._id, e.target.value);
              }}
            />
          </Grid>
        ))}

      {/* Simple PIPS */}
      {type === 'basic' &&
        simplePIPsBySelection &&
        projectPIP?.length !== 0 &&
        projectData &&
        city &&
        simplePIPsBySelection.map((field, index) => {
          if (field.name !== 'project__pi_city') {
            return (
              <SimplePIPS
                key={index}
                index={index}
                unit={form?.unitPIPValue}
                field={field}
                projectPIP={projectPIP}
                appendChanged={(fieldId, value) => handlePIPValueUpdate(fieldId, value)}
                type={type}
              />
            );
          }
          return null;
        })}

      {/* Action Buttons */}
      <Grid container direction="row" alignItems="end" justifyContent="flex-end" item xs={12} style={{ marginBottom: '7%' }}>
        <Grid item xs={2}>
          <PrimaryButton disabled={!checkErrorForZeros(requiredpips, form) || !locationInput} onClick={() => handleSave(next && next)} children="Save" />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <PrimaryButton disabled={!checkErrorForZeros(requiredpips, form) || !locationInput} onClick={() => handleNext(next && next)} children="next" />
        </Grid>
      </Grid>
    </>
  );
};
