import React, { useEffect, useContext, Suspense, useState } from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../components/common/header/Header';
import Sidebar from '../components/common/sidebar/Sidebar';
import Routes from './routes';
import { Typography } from '@material-ui/core';
import { UserContext } from '../routing/IndexRouting';
import { useAppApiRequest } from '../hooks/useAppApiRequest';
export const walkthroughContext = React.createContext();
export const InterventionCategoryContext = React.createContext();
export const IGBCCategoryContext = React.createContext();
export const InterventionMappingContext = React.createContext();
export const NotetakingContext = React.createContext();

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
  },
}));

export default function ProjectRoutes(props) {
  const { userDetails } = useContext(UserContext);
  let match = useRouteMatch();
  const sidebarLinks = [
    {
      url: `${match.url}/insights`,
      name: 'Insights',
    },
    {
      url: `${match.url}/interventions`,
      name: 'Interventions',
    },
    {
      url: `${match.url}/edit?type=basic`,
      name: 'Project Details',
    },
    {
      url: `${match.url}/certification`,
      name: 'Certification',
    },
    {
      url: `${match.url}/goals-insights`,
      name: 'Goals',
    },
    {
      url: `${match.url}/reports`,
      name: 'Reports',
    },
  ];

  const sidebarLink = [...sidebarLinks];
  const classes = useStyles();

  // project data
  const [projectData, setProjectData] = useState(null);
  const {
    data: mo,
    isError,
    isFetching,
  } = useAppApiRequest(
    `/projectv2/${props?.match?.params?.id}/basics`,
    { method: 'GET' },
    [props?.match?.params?.id],
    props?.match?.params?.id !== null && props?.match?.params?.id !== undefined
  );

  useEffect(() => {
    if (mo) {
      setProjectData(mo?.data?.data?.data);
    }
  }, [mo]);

  /*set interventionmapping context*/
  const [interventionmapping, setInterventionMapping] = useState('');
  const handleInterventionMapping = (data) => setInterventionMapping(data);

  /*walk through context */
  const [uiWalkthrough, setUiWalkthrough] = useState(false);
  const handleMyWalkthrough = (data) => setUiWalkthrough(data);
  /*Set Intervention Category Context*/
  const [interventionCategory, setinterventionCategory] = useState('energy');
  const handleInterventionCategory = (data) => setinterventionCategory(data);
  /*Set IGBC Category Context*/
  const [IGBCCategory, setIGBCCategory] = useState('Sustainable Design');
  const handleIGBCCategory = (data) => setIGBCCategory(data);

  /*note taking feature*/
  const [notetaking, setNotetaking] = useState(false);
  const handleNotetakingContext = () => setNotetaking(!notetaking);

  useEffect(() => {
    setUiWalkthrough(!!userDetails?.onboarding?.showHowItWorks);
  }, [userDetails]);

  return (
    <div className={classes.root}>
      <NotetakingContext.Provider value={{ notetakingContext: notetaking, handleNotetakingContext }}>
        <InterventionMappingContext.Provider value={{ interventionMappingContext: interventionmapping, handleInterventionMapping }}>
          <walkthroughContext.Provider value={{ uiWalkthroughContext: uiWalkthrough, handleMyWalkthrough }}>
            <InterventionCategoryContext.Provider value={{ interventionCategoryContext: interventionCategory, handleInterventionCategory }}>
              <IGBCCategoryContext.Provider value={{ igbcCategoryContext: IGBCCategory, handleIGBCCategory }}>
                <Header id={props.match.params.id} notetaking={notetaking} />
                <Sidebar links={sidebarLink} projectData={projectData} />
                <main className={classes.content}>
                  {projectData && (
                    <Suspense fallback={<Typography variant="body1">Loading...</Typography>}>
                      <Switch>
                        {Routes.map((route, index) => (
                          <Route key={index} exact={route.exact} path={`${match.path}${route.path}`} component={route.component} />
                        ))}
                        <Route
                          exact
                          path={`${match.path}`}
                          component={() => (
                            <>
                              <Redirect to={`${match.url}/insights`} />
                            </>
                          )}
                        />
                      </Switch>
                    </Suspense>
                  )}
                </main>
              </IGBCCategoryContext.Provider>
            </InterventionCategoryContext.Provider>
          </walkthroughContext.Provider>
        </InterventionMappingContext.Provider>
      </NotetakingContext.Provider>
    </div>
  );
}
