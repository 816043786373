import React, { useState, useEffect, useCallback, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography, FormGroup, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useRouteMatch } from 'react-router-dom';
import CustomInput from '../../../common/textfield/CustomInput';
import CustomLink from '../../../common/link/CustomLink';
import PrimaryButton from '../../../common/button/Button';
import axios from '../../../../config/Axios';
import { FormControlLabel } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import swal from 'sweetalert';
import api from '../../../../http';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { MetafireInterventions } from '../../../../metafire/utils/types';
import { getBaseImageUrl } from '../../../../utils/getBaseImageUrl';

const useStyles = makeStyles((theme) => ({
  spacing: {
    paddingTop: '4%',
  },
  form: {
    width: '100%',
    marginTop: '-1%',
  },
  formPaper: {
    background: '#FFFFFF',
    boxShadow: '2px 2px 14px rgba(0, 0, 0, 0.1)',
  },
  container: {
    paddingTop: '0%',
    paddingLeft: '3%',
    paddingBottom: '3%',
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 210,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modal2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 600,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paper2: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: '600px',
  },
}));

export default function UpdateMapping(props) {
  let match = useRouteMatch();
  const id = match.params.id;
  const classes = useStyles();
  const handleClose = () => props.history.push('/admin/intervention-vendor-mapping');
  const [textDescription, setTextDescription] = useState('');
  const [capexmultiplier, setCapexMultiplier] = useState('');
  const [resourceSaving, setResourceSaving] = useState('');
  const [areaRequiredToInstall, setAreaRequiredToInstall] = useState('');
  const [capex, setCapex] = useState(false);
  const [resource, setResource] = useState(false);
  const [area, setArea] = useState(false);
  const description = [
    {
      name: MetafireInterventions.solarPV,
      resource: 'Daily kWh generated per kWp installed',
      capex: 'Capex per kWp installed',
      area: 'Area required in sqm per kWp installed',
    },
    {
      name: MetafireInterventions.solarWaterHeater,
      resource: 'Daily kWh saved per LPD installed',
      capex: 'Capex per LPD installed',
      area: 'Area required in sqm per LPD installed',
    },
    {
      name: MetafireInterventions.wwm,
      resource: 'Not Applicable for this Intervention',
      capex: 'Capex per KG of Capacity installed',
      area: 'Area required in sqm per KG Capacity installed',
    },
    {
      name: MetafireInterventions.csp,
      resource: 'Not Applicable for this Intervention',
      capex: 'Area required in sqm per KG Capacity installed',
      area: 'Area required in sqm per KG Capacity installed',
    },
    {
      name: MetafireInterventions.sanitaryNapkinIncinerator,
      resource: 'Not Applicable for this Intervention',
      capex: 'Capex per 500 Napkins processing capacity',
      area: 'Area required in sqm per 500 Napkins processing capacity',
    },
    {
      name: MetafireInterventions.centralizedRO,
      resource: 'Not Applicable for this Intervention',
      capex: 'Capex per KL of capacity',
      area: 'Area required in sqm per KL of capacity',
    },
    {
      name: MetafireInterventions.efficientFixtures,
      resource: 'Not Applicable for this Intervention',
      capex: 'Not Applicable for this Intervention',
      area: 'Not Applicable for this Intervention',
    },
    {
      name: MetafireInterventions.dualFlush,
      resource: 'Not Applicable for this Intervention',
      capex: 'Not Applicable for this Intervention',
      area: 'Not Applicable for this Intervention',
    },
    {
      name: MetafireInterventions.swm,
      resource: 'Efficiency in % i.e. enter 0.94 if 6% savings potential',
      capex: 'Capex per daily KL capacity processed by Smart Meters',
      area: 'Not Applicable for this Intervention',
    },
    {
      name: MetafireInterventions.greyWaterReuse,
      resource: 'Not Applicable for this Intervention',
      capex: 'Capex per KL of capacity',
      area: 'Area required in sqm per KL of Capacity',
    },
    {
      name: MetafireInterventions.blackWaterReuse,
      resource: 'Not Applicable for this Intervention',
      capex: 'Capex per KL of capacity',
      area: 'Area required in sqm per KL of Capacity',
    },
    {
      name: MetafireInterventions.rooftopRWH,
      resource: 'Not Applicable for this Intervention',
      capex: 'Capex per KL of capacity',
      area: 'Area required in sqm per KL of Capacity',
    },
    {
      name: MetafireInterventions.surfaceRWH,
      resource: 'Not Applicable for this Intervention',
      capex: 'Capex per KL of capacity',
      area: 'Area required in sqm per KL of Capacity',
    },
  ];

  const [mapping, setMapping] = useState('');
  useEffect(() => {
    if (id) {
      axios
        .get(`/interventionVendorMapping/${id}`)
        .then((response) => {
          if (response && response.data) setMapping(response.data.data.data);
        })
        .catch((err) => {
          console.log(err, 'error');
        });
    }
  }, [id]);
  useEffect(() => {
    if (mapping) {
      let intervention = mapping.interventionScheme;
      let textdescription = description.filter((des) => des.name === intervention.name);
      setTextDescription(textdescription);
      setCapex(intervention.multipliers.capex);
      setResource(intervention.multipliers.resource);
      setArea(intervention.multipliers.area);
    }
  }, [mapping]);

  const [myArray, setMyArray] = useState([]);
  const [organisations, setOrganisations] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [manager, setManager] = useState('');
  const [aboutus, setAboutUs] = useState('');
  const [managerPhoto, setManagerPhoto] = useState('');

  const [installationTimeline, setInstallationTimeline] = useState('');
  const [features, setFeatures] = useState('');
  const [properties, setProperties] = useState('');
  const [maintainance, setMaintainance] = useState('');

  const [productImages, setProductImages] = useState([]);

  const [open, setOpen] = useState(false);
  const handleModalClose = () => setOpen(false);

  const handleProperties = (value) => setProperties(value);
  const handleMaintainance = (value) => setMaintainance(value);
  const handleIntallationTimeline = (value) => setInstallationTimeline(value);
  const handleFeatures = (value) => setFeatures(value);

  useEffect(() => {
    axios
      .get('/organisationsschemev2')
      .then((response) => {
        if (response && response.data) setOrganisations(response.data.data.data);
      })
      .catch((err) => {
        console.log(err, 'error');
      });
  }, []);

  const findOrganisation = (v) => {
    const data = organisations && organisations.find((d) => d._id === v);
    return data.name;
  };

  const appendChanged = (id) => {
    let n =
      myArray &&
      myArray.find((data) => {
        return data === id;
      });
    if (n) {
      n = id;
      setMyArray((oldArray) => [...oldArray]);
    } else {
      setMyArray((oldArray) => [...oldArray, id]);
    }
  };

  const handleSubmit = () => {
    const body = {
      vendor: mapping && mapping.vendor._id,
      interventionScheme: mapping && mapping.interventionScheme._id,
      contact: {
        manager: manager && manager ? manager : mapping && mapping.contact.manager,
        phone: phone && phone ? phone : mapping && mapping.contact.phone,
        email: email && email ? email : mapping && mapping.contact.email,
        photo: managerPhoto && managerPhoto ? managerPhoto : mapping.contact.photo,
      },
      installationTimeline: installationTimeline ? installationTimeline.split('|') : mapping && mapping.installationTimeline,
      features: features ? features.split('|') : mapping && mapping.features,
      properties: properties ? properties.split('|') : mapping && mapping.properties,
      maintainance: maintainance ? maintainance.split('|') : mapping && mapping.maintainance,
      aboutUs: aboutus && aboutus ? aboutus.split('|') : mapping && mapping.aboutUs,
      productImages: productImages && productImages.length !== 0 ? productImages : mapping && mapping.productImages,
      clientRecommendations: myArray && myArray.length !== 0 ? myArray : mapping && mapping.clientRecommendations,
      quantities: {
        capexmultiplier: capexmultiplier && capexmultiplier ? capexmultiplier : mapping && mapping.quantities.capexmultiplier,
        resourceSavingsMultiplier: resourceSaving && resourceSaving ? resourceSaving : mapping && mapping.quantities.resourceSavingsMultiplier,
        areaRequiredtoInstall: areaRequiredToInstall && areaRequiredToInstall ? areaRequiredToInstall : mapping && mapping.quantities.areaRequiredtoInstall,
      },
    };
    axios
      .put(`/interventionVendorMapping/${id}`, body)
      .then((response) => {
        if (response) {
          props.history.push(`/admin/intervention-vendor-mapping`);
        }
      })
      .catch((err) => {
        console.log(err, 'error');
      });
  };
  /////////////////////////////
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [file, setFile] = useState('');
  const [name, setName] = useState('');
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 9 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [openPic, setOpenPic] = useState(false);

  /////////////////////////////
  const onSelectProductFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setOpenPic(true);
      setName('product');
    }
  };
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setOpenPic(true);
      setName('manager');
    }
  };
  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);
  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';
    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);
  }, [completedCrop]);
  const handlepicModalClose = () => {
    setOpenPic(false);
    setFile('');
    setUpImg('');
    imgRef.current = null;
  };
  const handleUpload = async () => {
    if (file) {
      const signedUrl = await api.getVendorsPresignedURL(file.name);
      if (!signedUrl) throw new Error('Failed to get presigned URL');
      const uploadSuccessful = await api.putUploadToS3(signedUrl, file);
      if (uploadSuccessful?.status !== 200) throw new Error('Upload failed');
      const viewImageURL = getBaseImageUrl(signedUrl);
      if (viewImageURL) {
        const body = {
          URL: viewImageURL,
        };
        try {
          const response = await api.createStaticAsset(body);
          if (response && response.data.data) {
            if (name === 'product') {
              productImages.push(response.data.data.data._id);
            } else if (name === 'manager') {
              setManagerPhoto(response.data.data.data._id);
            }
            setFile('');
            setOpen(false);
            setOpenPic(false);
            setName('');
            setUpImg('');
            handlepicModalClose();
            swal({ icon: 'success', title: 'Successfully Uploaded Image. You can upload multiple images only for product images.' });
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  return (
    <>
      <Grid container alignItems="center" item xs={12} style={{ marginBottom: '5%' }}>
        <Grid item xs={8} align="left">
          <Typography variant="h3">Update Intervention Vendor Mapping</Typography>
        </Grid>

        <Grid container alignItems="center" item xs={12} style={{ marginTop: '3%' }}>
          <Grid item xs={8} align="left">
            <Typography variant="h5">Update Vendor Contact Details</Typography>
          </Grid>
          <form className={classes.form} noValidate>
            <>
              <Grid container alignItems="center" item xs={12} className={classes.container}>
                <Grid item xs={3} style={{ marginTop: '3%' }}>
                  <Grid>
                    <Typography variant="h6">
                      <b> Existing Manager Name </b>:<br /> {mapping && mapping.contact.manager}
                    </Typography>
                  </Grid>
                  <Grid direction="column">
                    <CustomInput
                      margin="normal"
                      required
                      value={manager}
                      onChange={(e) => setManager(e.target.value)}
                      placeholder="Name"
                      fullWidth
                      id="standard-multiline-flexible"
                      label="Update Manager name"
                      name="manager"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={3} style={{ marginTop: '3%', marginLeft: '8%' }}>
                  <Grid>
                    <Typography variant="h6">
                      <b> Existing Email Id </b>:<br /> {mapping && mapping.contact.email}
                    </Typography>
                  </Grid>
                  <Grid direction="column">
                    <CustomInput
                      margin="normal"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email"
                      fullWidth
                      id="standard-multiline-flexible"
                      label="Update Email"
                      name="email"
                    />
                  </Grid>
                </Grid>

                <Grid item xs={3} style={{ marginTop: '3%', marginLeft: '8%' }}>
                  <Grid>
                    <Typography variant="h6">
                      <b> Existing Phone Number </b>:<br /> {mapping && mapping.contact.phone}
                    </Typography>
                  </Grid>
                  <Grid direction="column">
                    <CustomInput
                      margin="normal"
                      required
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      placeholder="Phone"
                      fullWidth
                      id="standard-multiline-flexible"
                      label="Update Phone"
                      name="phone"
                    />
                  </Grid>
                </Grid>

                <Grid item xs={3} style={{ marginTop: '3%' }}>
                  <Grid>
                    <Typography variant="h6">
                      <b> Existing Manager Photo </b>:<br /> {mapping && mapping.contact.photo}
                    </Typography>
                  </Grid>
                  <Grid direction="column">
                    <br />
                    <div>
                      <input type="file" accept="image/*" onChange={onSelectFile} />
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              {/* //////// */}

              <Grid item xs={8} align="left">
                <Typography variant="h5">Update Vendor Details</Typography>
              </Grid>

              <Grid container alignItems="center" item xs={12} className={classes.container}>
                <Grid item xs={3} style={{ marginTop: '3%' }}>
                  <Grid>
                    <Typography variant="h6">
                      <b> Existing Vendor Properties </b>:{' '}
                      {mapping &&
                        mapping.properties.map((v) => {
                          return <Typography className={classes.secondaryHeading}>{v}</Typography>;
                        })}
                    </Typography>
                  </Grid>
                  <Grid direction="column">
                    <CustomInput
                      margin="normal"
                      required
                      value={properties}
                      onChange={(e) => handleProperties(e.target.value)}
                      placeholder="Properties"
                      fullWidth
                      id="standard-multiline-flexible"
                      label="Update Properties"
                      name="properties"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={3} style={{ marginTop: '3%', marginLeft: '8%' }}>
                  <Grid>
                    <Typography variant="h6">
                      <b> Existing Maintaninance </b>:{' '}
                      {mapping &&
                        mapping.maintainance.map((v) => {
                          return <Typography className={classes.secondaryHeading}>{v}</Typography>;
                        })}
                    </Typography>
                  </Grid>
                  <Grid direction="column">
                    <CustomInput
                      margin="normal"
                      required
                      value={maintainance}
                      onChange={(e) => handleMaintainance(e.target.value)}
                      placeholder="Maintainance"
                      fullWidth
                      id="standard-multiline-flexible"
                      label="Maintainance"
                      name="Maintainance"
                    />
                  </Grid>
                </Grid>

                <Grid item xs={3} style={{ marginTop: '3%', marginLeft: '8%' }}>
                  <Grid>
                    <Typography variant="h6">
                      <b> Existing Installation Timeline </b>:{' '}
                      {mapping &&
                        mapping.installationTimeline.map((v) => {
                          return <Typography className={classes.secondaryHeading}>{v}</Typography>;
                        })}
                    </Typography>
                  </Grid>
                  <Grid direction="column">
                    <CustomInput
                      margin="normal"
                      required
                      value={installationTimeline}
                      onChange={(e) => handleIntallationTimeline(e.target.value)}
                      placeholder="Installation Timeline"
                      fullWidth
                      id="standard-multiline-flexible"
                      label="Update Installation Timeline"
                      name="installation timeline"
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* //////////// */}

              <Grid container alignItems="center" item xs={12} className={classes.container}>
                <Grid item xs={3} style={{ marginTop: '3%' }}>
                  <Grid>
                    <Typography variant="h6">
                      <b> Existing Vendor Features </b>:{' '}
                      {mapping &&
                        mapping.features.map((v) => {
                          return <Typography className={classes.secondaryHeading}>{v}</Typography>;
                        })}
                    </Typography>
                  </Grid>
                  <Grid direction="column">
                    <CustomInput
                      margin="normal"
                      required
                      value={features}
                      onChange={(e) => handleFeatures(e.target.value)}
                      placeholder="Features"
                      fullWidth
                      id="standard-multiline-flexible"
                      label="Update Features"
                      name="features"
                    />
                  </Grid>
                </Grid>

                <Grid item xs={3} style={{ marginTop: '3%', marginLeft: '8%' }}>
                  <Grid>
                    <Typography variant="h6">
                      <b> Existing Product Images</b>:{' '}
                      {mapping && mapping.productImages.length === 0 ? (
                        <Typography className={classes.secondaryHeading}>no-Images</Typography>
                      ) : (
                        mapping &&
                        mapping.productImages.map((v) => {
                          return <Typography className={classes.secondaryHeading}>{v}</Typography>;
                        })
                      )}
                    </Typography>
                  </Grid>
                  <Grid direction="column">
                    <br />
                    <div>
                      <input type="file" accept="image/*" onChange={onSelectProductFile} />
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={3} style={{ marginTop: '3%', marginLeft: '8%' }}>
                  <Grid>
                    <Typography variant="h6">
                      <b> Existing About Us </b>:
                    </Typography>
                    <Typography variant="h6" style={{ marginTop: '2%' }}>
                      {mapping && mapping.aboutUs}
                    </Typography>
                  </Grid>
                  <Grid direction="column">
                    <CustomInput
                      margin="normal"
                      required
                      value={aboutus}
                      onChange={(e) => setAboutUs(e.target.value)}
                      placeholder="About Us"
                      fullWidth
                      id="standard-multiline-flexible"
                      label="Update About Us"
                      name="about us"
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* /////////////////// */}

              <Grid container alignItems="center" item xs={12} className={classes.container}>
                <Grid item xs={3} style={{ marginTop: '3%' }}>
                  <Grid>
                    <Typography variant="h6">
                      <b> Capex Multiplier </b>{' '}
                      {textDescription ? (
                        <Typography variant="h6" style={{ color: '#666666', fontSize: '12px', paddingTop: '2%' }}>
                          {textDescription[0].capex}
                        </Typography>
                      ) : (
                        ''
                      )}
                      <br /> {mapping && mapping.quantities.capexmultiplier}
                    </Typography>
                  </Grid>
                  <Grid direction="column">
                    {capex === true ? (
                      <CustomInput
                        margin="normal"
                        required
                        value={capexmultiplier}
                        onChange={(e) => setCapexMultiplier(e.target.value)}
                        fullWidth
                        id="standard-multiline-flexible"
                        placeholder="update Capex Multiplier*"
                        name="capex"
                      />
                    ) : (
                      <CustomInput
                        margin="normal"
                        required
                        value={capexmultiplier}
                        onChange={(e) => setCapexMultiplier(e.target.value)}
                        fullWidth
                        id="standard-multiline-flexible"
                        placeholder="update Capex Multiplier*"
                        disabled
                        name="capex"
                      />
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={3} style={{ marginTop: '3%', marginLeft: '8%' }}>
                  <Grid>
                    <Typography variant="h6">
                      <b> Resource Multiplier</b>{' '}
                      {textDescription ? (
                        <Typography variant="h6" style={{ color: '#666666', fontSize: '12px', paddingTop: '2%' }}>
                          {textDescription[0].resource}
                        </Typography>
                      ) : (
                        ''
                      )}
                      <br /> {mapping && mapping.quantities.resourceSavingsMultiplier}
                    </Typography>
                  </Grid>
                  <Grid direction="column">
                    {resource === true ? (
                      <CustomInput
                        margin="normal"
                        required
                        value={resourceSaving}
                        onChange={(e) => setResourceSaving(e.target.value)}
                        // placeholder="Resource Saving Multiplier"
                        fullWidth
                        id="standard-multiline-flexible"
                        placeholder="Update Resource Multiplier*"
                        name="resource saving"
                      />
                    ) : (
                      <CustomInput
                        margin="normal"
                        required
                        disabled
                        value={resourceSaving}
                        onChange={(e) => setResourceSaving(e.target.value)}
                        fullWidth
                        id="standard-multiline-flexible"
                        placeholder="Update Resource Multiplier*"
                        name="resource saving"
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={3} style={{ marginTop: '3%', marginLeft: '8%' }}>
                  <Grid>
                    <Typography variant="h6">
                      <b> Area Multiplier </b>{' '}
                      {textDescription ? (
                        <Typography variant="h6" style={{ color: '#666666', fontSize: '12px', paddingTop: '2%' }}>
                          {textDescription[0].area}
                        </Typography>
                      ) : (
                        ''
                      )}{' '}
                      <br />
                      {mapping && mapping.quantities.areaRequiredtoInstall}
                    </Typography>
                  </Grid>
                  <Grid direction="column">
                    {area === true ? (
                      <CustomInput
                        margin="normal"
                        required
                        value={areaRequiredToInstall}
                        onChange={(e) => setAreaRequiredToInstall(e.target.value)}
                        fullWidth
                        id="standard-multiline-flexible"
                        placeholder="Update Area Multiplier*"
                        name="total area"
                      />
                    ) : (
                      <CustomInput
                        margin="normal"
                        required
                        disabled
                        value={areaRequiredToInstall}
                        onChange={(e) => setAreaRequiredToInstall(e.target.value)}
                        fullWidth
                        id="standard-multiline-flexible"
                        placeholder="Update Area Multiplier*"
                        name="total area"
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {/* /////////// */}

              <Grid container alignItems="center" item xs={12} className={classes.container}>
                <Grid item xs={3} style={{ marginTop: '3%' }}>
                  <Grid>
                    <Typography variant="h6">
                      <b> Existing Client Recomendation </b>:{' '}
                      {mapping && mapping.clientRecommendations.length === 0 ? (
                        <Typography className={classes.secondaryHeading}>no-recomendations</Typography>
                      ) : (
                        mapping &&
                        mapping.clientRecommendations.map((v) => {
                          return <Typography className={classes.secondaryHeading}>{findOrganisation(v)}</Typography>;
                        })
                      )}
                    </Typography>
                  </Grid>
                  <Grid direction="column" style={{ marginTop: '5%' }}>
                    <Link style={{ background: 'transparent', color: 'rgba(0, 219, 153, 0.82)', width: '100%' }} onClick={() => setOpen(true)}>
                      select clients <AddCircleIcon />
                    </Link>
                  </Grid>
                </Grid>
              </Grid>

              {/* //////////// */}
            </>

            <Grid container item xs={12} className={classes.spacing}>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <Grid container item xs={12}>
                  <Grid item xs={6}>
                    <CustomLink onClick={handleClose} activeOnlyWhenExact={true} label={<Typography variant="h5">CANCEL </Typography>} />
                  </Grid>
                  <Grid item xs={6}>
                    <PrimaryButton children="UPDATE" onClick={() => handleSubmit()} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Typography gutterBottom variant="h5" component="h2">
              Update Clients Recommend this vendor
            </Typography>
            <Typography gutterBottom variant="h6" component="h2">
              <b>IMPORTANT</b>: Once Selected only those values will get updated for the vendor.
            </Typography>
            <br />
            <FormGroup row>
              {organisations &&
                organisations.map((v) => {
                  return (
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={false}
                            onChange={() => {
                              appendChanged(v._id);
                            }}
                            name={v.name}
                          />
                        }
                        label={v.name}
                      />
                    </>
                  );
                })}
            </FormGroup>
            <br />
            <Link size="small" color="primary" onClick={handleModalClose}>
              Add
            </Link>
            <Link size="small" color="primary" onClick={handleModalClose} style={{ marginLeft: '10%' }}>
              Cancel
            </Link>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal2}
        open={openPic}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openPic}>
          <div className={classes.paper2}>
            <Grid container alignItems="center" item xs={12} style={{ marginBottom: '3%' }}>
              <Grid item xs={6} align="left">
                <Grid item xs={12}>
                  <ReactCrop src={upImg} onImageLoaded={onLoad} crop={crop} onChange={(c) => setCrop(c)} onComplete={(c) => setCompletedCrop(c)} />
                  <div>
                    <canvas
                      ref={previewCanvasRef}
                      style={{
                        width: Math.round(completedCrop?.width ?? 0),
                        height: Math.round(completedCrop?.height ?? 0),
                        maxHeight: '600px',
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <button
              type="button"
              disabled={!completedCrop?.width || !completedCrop?.height}
              // onClick={() => generateDownload(previewCanvasRef.current, completedCrop)}
            >
              Download cropped image
            </button>
            <br />
            <br />
            <Link size="small" color="primary" onClick={handleUpload}>
              Upload
            </Link>
            <Link size="small" color="primary" onClick={handlepicModalClose} style={{ marginLeft: '10%' }}>
              Cancel
            </Link>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
