import { Grid, Modal, Paper, Typography, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { useInterventionDetailsContext } from '../context/InterventionDetailsV2';
import ShowSaveAndCancel from '../components/showSaveAndCancel';
import KongInterventionsComponentsMapper from './kong/KongInterventions';
import ProjectInputs from './ProjectInputs';

const DataAndCostAssumptions = ({ isOpen, onClose }) => {
  const classes = useStyles();
  const [parameter, setParameter] = useState('basic');
  const { set, key } = useInterventionDetailsContext();
  const [saveError, setSaveError] = useState(false);

  const handleUpdateSandbox = async () => {
    await set.updateSandbox();
    onClose();
  };

  const handleCancelSandbox = () => {
    set.resetQuantityValues();
    onClose();
  };

  const KongBasicComponent = KongInterventionsComponentsMapper[key]?.basic;
  const KongAdvancedComponent = KongInterventionsComponentsMapper[key]?.advanced;
  const KongProjectInputsComponent = ProjectInputs;

  // Default components for Data & Cost Assumptions if a Kong Implementation does not exist
  const BasicInterventionComponent = KongInterventionsComponentsMapper['default']?.basic;
  const AdvancedInterventionComponent = KongInterventionsComponentsMapper['default']?.advanced;

  return (
    <Modal
      disableEnforceFocus
      style={{
        position: 'absolute',
      }}
      open={isOpen}
      onClose={handleCancelSandbox}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div
        style={{
          top: `${50}%`,
          left: `${50}%`,
          transform: `translate(-${50}%, -${50}%)`,
        }}
        className={classes.paper}
      >
        <Grid container item xs={12}>
          <Grid item xs={3}>
            <Paper style={{}} className={parameter === 'basic' ? classes.button1 : classes.button2} onClick={() => setParameter('basic')}>
              <Typography variant="h6" className={parameter === 'basic' ? classes.textinside1 : classes.textinside2} style={{}}>
                Basic Parameters
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={1}></Grid>

          <Grid item xs={3}>
            <Paper style={{}} className={parameter === 'advance' ? classes.button1 : classes.button2} onClick={() => setParameter('advance')}>
              <Typography variant="h6" className={parameter === 'advance' ? classes.textinside1 : classes.textinside2}>
                Advanced Parameters
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={1}></Grid>

          <Grid item xs={3}>
            <Paper className={parameter === 'project' ? classes.button1 : classes.button2} onClick={() => setParameter('project')}>
              <Typography variant="h6" className={parameter === 'project' ? classes.textinside1 : classes.textinside2}>
                Project Inputs
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        <Grid container item xs={12} style={{ marginTop: '3%' }} />

        {parameter === 'basic' && (KongBasicComponent ? <KongBasicComponent setSaveError={setSaveError} /> : <BasicInterventionComponent />)}

        {parameter === 'advance' && (KongAdvancedComponent ? <KongAdvancedComponent /> : <AdvancedInterventionComponent onClick={() => {}} />)}

        {parameter === 'project' && KongProjectInputsComponent && <KongProjectInputsComponent />}

        <Grid container item xs={12} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
          <ShowSaveAndCancel handleClose={() => handleCancelSandbox()} handleSave={() => handleUpdateSandbox()} minError={saveError && saveError} />
        </Grid>
      </div>
    </Modal>
  );
};
export default DataAndCostAssumptions;

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '950px',
    outline: 'none',
    maxHeight: 500,
    backgroundColor: '#FFFFFF',
    borderRadius: '15px',
    padding: theme.spacing(5, 5, 2, 5),
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  edit: {
    color: '#00C58A',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  grid: {
    marginTop: '2%',
  },
  textinside1: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.5px',
    color: '#FFFFFF',
    textAlign: 'center',
    paddingTop: '10%',
  },
  textinside2: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.5px',
    color: 'rgba(0, 0, 0, 0.6)',
    textAlign: 'center',
    paddingTop: '10%',
  },
  button1: {
    filter: 'drop-shadow(0px 4px 20px rgba(0, 197, 138, 0.4))',
    width: '223px',
    height: '65px',
    background: '#66E3BE',
    borderRadius: '10px',
    boxShadow: 'none',
    boxSizing: 'border-box',
    cursor: 'pointer',
  },
  button2: {
    width: '223px',
    height: '65px',
    background: '#FFFFFF',
    borderRadius: '10px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    boxShadow: 'none',
    boxSizing: 'border-box',
    cursor: 'pointer',
  },
}));
