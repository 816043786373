import axios from 'axios';
import cookie from 'react-cookies';

const BASE_URI = process.env.REACT_APP_BACKEND_BASE_URI;
let axiosAPI = axios.create({ baseURL: BASE_URI });

const updateToken = (token) => {
  axiosAPI.defaults.headers.common = { Authorization: `Bearer ${token}` };
};

const redirectToHome = () => {
  // TODO: add toast message
  window.location.href = '/';
};

const redirectToLogin = () => {
  // TODO: add toast message
  cookie.remove('SDPLUS_TOKEN');
  window.location.href = '/';
};

const handleError = (error) => {
  if (error.response.status === 403) redirectToHome();

  if (error.response.status === 401) redirectToLogin();

  throw error;
};

const getRequest = async (url) => {
  try {
    return await axiosAPI.get(url);
  } catch (error) {
    handleError(error);
  }
};

const postRequest = async (url, body) => {
  try {
    return await axiosAPI.post(url, body);
  } catch (error) {
    handleError(error);
  }
};

const putRequest = async (url, body) => {
  try {
    return await axiosAPI.put(url, body);
  } catch (error) {
    handleError(error);
  }
};

const deleteRequest = async (url, data = {}) => {
  try {
    return await axiosAPI.delete(url, { data: data });
  } catch (error) {
    handleError(error);
  }
};

const api = { get: getRequest, post: postRequest, put: putRequest, delete: deleteRequest };

export { api, BASE_URI, updateToken };
