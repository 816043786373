/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import InterventionDetailsHeader from './main/InterventionDetailsHeader';

import Backdrop from '@material-ui/core/Backdrop';

import { useParams } from 'react-router';

import CircularProgress from '@material-ui/core/CircularProgress';
import { PreviousPageContext } from '../../../../routing/IndexRouting';
import { MarketPlaceLink } from './marketplace-link/MarketplaceLink';

import InterventionDetailsContext, { useInterventionDetailsContext } from './context/InterventionDetailsV2';
import InterventionDetailsSummary from './main/InterventionDetailsSummary';
import InterventionDetailsTour from './main/InterventionDetailsTour';
import ApiError from '../../../common/Error/ApiError';
import InterventionNoteTaking from './main/InterventionNoteTaking';
import CompareInterventionSets from './interventionSets/compareInterventionSets';

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: '56px',
    padding: theme.spacing(3),
    position: 'relative',
  },
  root: {
    paddingTop: '3%',
  },
  overview: {
    flexGrow: '1',
    maxWidth: '100%',
    flexBasis: '100%',
  },
  rightOverview: {
    marginTop: '1%',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    maxWidth: 900,
    minWidth: 700,
    minHeight: 400,
    padding: theme.spacing(2, 4, 3),
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
    borderRadius: '10px',
  },
  paperModal: {
    position: 'absolute',
    outline: 'none',
    height: 'auto',
    width: 'auto',
    backgroundColor: '#FFFFFF',
    borderRadius: '15px',
    padding: theme.spacing(2, 4, 2),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  vendorGrid: {
    marginTop: '3%',
  },
  close: {
    marginLeft: '95%',
  },
  backdrop: {
    zIndex: 1,
    color: '#fff',
  },
}));

function InterventionDetails() {
  const { intervention, request } = useInterventionDetailsContext();
  const params = useParams();
  const classes = useStyles();
  // const [modalStyle] = React.useState(getModalStyle);
  const { setPreviousPage, setProjectID, setInterventionID } = useContext(PreviousPageContext);

  // TODO: Why are we using a PreviousPageContext. Can it be managed easily using react-router-dom?
  useEffect(() => {
    if (params?.id && params?.iid) {
      setPreviousPage('interventionDetails');
      setProjectID(params?.id);
      setInterventionID(params?.iid);
    }
  }, [params]);

  if (request?.isError) {
    return (
      <Grid className={classes.main}>
        <ApiError />
      </Grid>
    );
  }
  return (
    <Grid className={classes.main}>
      <Backdrop open={request?.isFetching} className={classes.backdrop} style={{ zIndex: 3, color: '#66E3BE' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <>
        <>
          <InterventionDetailsTour showTour={request?.isFetching !== false} />
          <InterventionDetailsHeader />
          <InterventionDetailsSummary projectId={params?.id} interventionId={params?.iid} />
          <MarketPlaceLink interventionname={intervention?.name} />
          <InterventionNoteTaking category="INTERVENTION" />
        </>
      </>
    </Grid>
  );
}

const InterventionDetailsContextComponent = (props) => {
  return (
    <InterventionDetailsContext>
      <InterventionDetails {...props} />
    </InterventionDetailsContext>
  );
};

export default InterventionDetailsContextComponent;
