import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from '../../../../config/Axios';
import useApiRequest from '../../../hooks/useApiRequest';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import SearchBar from '../../../common/Searchbar/Search';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import swal from 'sweetalert';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Link } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import api from '../../../../http';
import { getBaseImageUrl } from '../../../../utils/getBaseImageUrl';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: '2%',
  },
  form: {
    '& > *': {
      margin: theme.spacing(1),
      width: '15ch',
    },
  },
  paper: {
    width: '100%',
    outline: 'none',
    height: 'auto',
    backgroundColor: '#FFFFFF',
    borderRadius: '15px',
    padding: theme.spacing(2, 4, 3),
  },
  spacing: {
    paddingTop: '2vh',
  },
}));

const Interventions = () => {
  const [selectedValue, setSelectedValue] = useState('');
  const [interventions, setInterventions] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [searchIntervention, setsearchIntervention] = useState('');
  const classes = useStyles();
  const [areaMultiplier, setAreaMultiplier] = useState(false);
  const [capexMultiplier, setCapexMultiplier] = useState(false);
  const [resourceMultiplier, setResourceMultiplier] = useState(false);

  const data = [1, 2, 3, 4, 5, 6];
  const [name, setName] = useState('');
  const [description, setDescription] = React.useState('');
  const [category, setCategory] = useState('');
  const [subcategory, setSubcategory] = useState('');
  const [passiveId, setPassiveId] = useState('');

  const [allCategory, setAllCategory] = useState('');
  const [allSubCategory, setAllSubCategory] = useState('');

  const [open, setOpen] = useState(false);
  const [id, setId] = useState('');
  const handlePost = () => {
    let body = { name: name, description: description, category: category, subCategory: subcategory, passiveId: passiveId };
    axios
      .post(`/interventionschemev2`, body)
      .then(() => {
        swal({ icon: 'success', title: 'Successfully Created Intervention' });
        makeRequest();
        setName('');
        setDescription('');
        setCategory('');
        setSubcategory('');
        setPassiveId('');
      })
      .catch((err) => console.log(err, 'err'));
  };

  ///////////////////
  const handleName = (event) => setName(event.target.value);
  const handleDescription = (event) => setDescription(event.target.value);
  const handleCategory = (event, newValue) => {
    if (newValue) {
      setCategory(newValue._id);
    }
  };
  const handlePassiveId = (event, newValue) => {
    if (newValue) {
      setPassiveId(newValue._id);
    }
  };
  const handleChangeSubCategory = (event, newValue) => {
    if (newValue) {
      setSubcategory(newValue._id);
    }
  };

  const [{ status, response }, makeRequest] = useApiRequest('/interventionschemev2', {
    verb: 'get',
  });
  if (status === null) makeRequest();
  useEffect(() => {
    if (response) {
      setInterventions(response.data.data);
    }
  }, [response]);
  useEffect(() => {
    axios.get('/categorySchemeV2').then((response) => {
      setAllCategory(response.data.data);
    });
    axios.get('/subcategoryschemev2').then((response) => {
      setAllSubCategory(response.data.data);
    });
  }, [response]);
  const handleTextField = (e) => {
    setSearchValue(e.target.value);
    const newData = interventions && interventions.data.filter((category) => category.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1);
    setsearchIntervention(newData);
  };
  const handleOpen = () => {
    setOpen(!open);
  };
  //////////////////
  const handleEditName = (e) => {
    let body = { name: name };
    axios.put(`/interventionschemev2/${id}`, body).then((response) => {
      swal({ icon: 'success', title: 'Successfully Edited Name' });
      makeRequest();
      setName('');
      setSelectedValue('');
    });
  };
  const handleEditDescription = (e) => {
    let body = { description: description };
    axios.put(`/interventionschemev2/${id}`, body).then((response) => {
      swal({ icon: 'success', title: 'Successfully Edited Description' });
      makeRequest();
      setDescription('');
      setSelectedValue('');
    });
  };
  const handleEditCategory = (e) => {
    let body = { category: category };
    axios.put(`/interventionschemev2/${id}`, body).then((response) => {
      swal({ icon: 'success', title: 'Successfully Edited Category' });
      makeRequest();
      setCategory('');
      setSelectedValue('');
    });
  };
  const handleEditSubCategory = (e) => {
    let body = { subCategory: subcategory };
    axios.put(`/interventionschemev2/${id}`, body).then((response) => {
      swal({ icon: 'success', title: 'Successfully Edited SubCategory' });
      makeRequest();
      setSubcategory('');
      setSelectedValue('');
    });
  };
  const handleEditPassiveId = (e) => {
    let body = { passiveId: passiveId };
    axios.put(`/interventionschemev2/${id}`, body).then((response) => {
      swal({ icon: 'success', title: 'Successfully Edited PassiveId' });
      makeRequest();
      setPassiveId('');
      setSelectedValue('');
    });
  };

  ///////////////////
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [file, setFile] = useState('');
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 9 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [upload, setUpload] = useState(false);
  const [asset, setAsset] = useState('');
  const [show, setShow] = useState(false);
  const [howitworks, setHowitworks] = useState('');
  const [staticassetids, setStaticassetids] = useState([]);
  const handleHowitworks = (event) => setHowitworks(event.target.value);
  ////////////////////
  const handleUpload = async () => {
    if (file) {
      const signedUrl = await api.getVendorsPresignedURL(file.name);
      if (!signedUrl) throw new Error('Failed to get presigned URL');
      const uploadSuccessful = await api.putUploadToS3(signedUrl, file);
      if (uploadSuccessful?.status !== 200) throw new Error('Upload failed');
      const viewImageURL = getBaseImageUrl(signedUrl);
      if (viewImageURL) {
        const body = {
          URL: viewImageURL,
        };
        try {
          const response = await api.createStaticAsset(body);
          if (response && response.data.data) {
            staticassetids.push(response.data.data.data._id);
            setAsset(response.data.data.data);
            setShow(true);
            setFile('');
            setUpImg('');
            swal({ icon: 'success', title: `Image Upload Successfull. You can Upload more image or click on the edit to edit metadata` });
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };
  const handleNonpost = () => {
    swal({ icon: 'error', title: `Please Select an Image` });
  };

  /////////////////////////////////////////
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);
  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';
    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);
  }, [completedCrop]);

  const handleEditMetadata = () => {
    let body = {
      metadata: {
        howitworks: howitworks,
        infographics: {
          staticassetids: staticassetids,
        },
      },
    };
    axios.put(`/interventionschemev2/${id}`, body).then((response) => {
      swal({ icon: 'success', title: 'Successfully Edited Metadata' });
      makeRequest();
      setSubcategory('');
      setSelectedValue('');
    });
  };
  const handleClose = () => {
    setSelectedValue('');
    setFile('');
    setUpImg('');
    setHowitworks('');
    setStaticassetids('');
    setAreaMultiplier(false);
    setCapexMultiplier(false);
    setResourceMultiplier(false);
  };
  const handleMultipliers = () => {
    let body = {
      multipliers: {
        area: areaMultiplier,
        capex: capexMultiplier,
        resource: resourceMultiplier,
      },
    };
    axios.put(`/interventionschemev2/${id}`, body).then((response) => {
      swal({ icon: 'success', title: 'Successfully Edited Metadata' });
      makeRequest();
      setSubcategory('');
      setSelectedValue('');
      setAreaMultiplier(false);
      setCapexMultiplier(false);
      setResourceMultiplier(false);
    });
  };

  return (
    <>
      <Grid container alignItems="center" item xs={12} style={{ marginBottom: '5%' }}>
        <Grid item xs={6} align="left">
          <Typography variant="h3">Interventions Management</Typography>
        </Grid>
        {/* <Grid item xs={1} /> */}
        <Grid item xs={3}>
          <SearchBar onChange={handleTextField} value={searchValue} placeholder="Search by Intervention name" />
        </Grid>
        <Grid item xs={1} />

        <Grid item xs={2}>
          <Link style={{ background: 'transparent', color: 'rgba(0, 219, 153, 0.82)', width: '20%' }} onClick={handleOpen}>
            Add <AddCircleIcon />
          </Link>
        </Grid>
      </Grid>
      {selectedValue && selectedValue === 'name' ? (
        <Grid container alignItems="center" item xs={12} style={{ marginBottom: '3%' }}>
          <Grid item xs={9} align="left">
            <form className={classes.form} noValidate autoComplete="off">
              <TextField id="standard-basic" label="Name" onChange={handleName} value={name} />
            </form>
          </Grid>
          <Grid item xs={3} align="left">
            <Button variant="contained" color="primary" onClick={handleEditName} style={{ marginRight: '2%' }}>
              Edit
            </Button>
            <Button variant="contained" color="secondary" onClick={handleClose} className={classes.margin}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs />
        </Grid>
      ) : (
        ''
      )}

      {selectedValue && selectedValue === 'Description' ? (
        <Grid container alignItems="center" item xs={12} style={{ marginBottom: '3%' }}>
          <Grid item xs={9} align="left">
            <form className={classes.form} noValidate autoComplete="off">
              <TextField id="standard-basic" label="Description" value={description} onChange={handleDescription} />
            </form>
          </Grid>
          <Grid item xs={3} align="left">
            <Button variant="contained" color="primary" onClick={handleEditDescription} style={{ marginRight: '2%' }}>
              Edit
            </Button>
            <Button variant="contained" color="secondary" onClick={handleClose} className={classes.margin}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs />
        </Grid>
      ) : (
        ''
      )}
      {selectedValue && selectedValue === 'multipliers' ? (
        <Grid container item xs={12} style={{ marginBottom: '50px' }}>
          <Grid item xs={3}>
            <Grid container itam xs={12}>
              <Grid item xs={2}>
                <Typography variant="h6">Area</Typography>
              </Grid>
              <Grid item xs={4}>
                {areaMultiplier ? (
                  <CheckBoxIcon style={{ cursor: 'pointer', color: '#1FDEDB' }} onClick={() => setAreaMultiplier(!areaMultiplier)} />
                ) : (
                  <CheckBoxOutlineBlankIcon style={{ cursor: 'pointer', color: '#1FDEDB' }} onClick={() => setAreaMultiplier(!areaMultiplier)} />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container itam xs={12}>
              <Grid item xs={2}>
                <Typography variant="h6">Capex</Typography>
              </Grid>
              <Grid item xs={4}>
                {capexMultiplier ? (
                  <CheckBoxIcon style={{ cursor: 'pointer', color: '#1FDEDB' }} onClick={() => setCapexMultiplier(!capexMultiplier)} />
                ) : (
                  <CheckBoxOutlineBlankIcon style={{ cursor: 'pointer', color: '#1FDEDB' }} onClick={() => setCapexMultiplier(!capexMultiplier)} />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid container itam xs={12}>
              <Grid item xs={4}>
                <Typography variant="h6">Resource</Typography>
              </Grid>
              <Grid item xs={4}>
                {resourceMultiplier ? (
                  <CheckBoxIcon style={{ cursor: 'pointer', color: '#1FDEDB' }} onClick={() => setResourceMultiplier(!resourceMultiplier)} />
                ) : (
                  <CheckBoxOutlineBlankIcon style={{ cursor: 'pointer', color: '#1FDEDB' }} onClick={() => setResourceMultiplier(!resourceMultiplier)} />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Button style={{ color: '#ffff', background: '#1FDEDB' }} onClick={handleMultipliers}>
              Save
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button style={{ color: '#ffff', background: '#1FDEDB' }} onClick={handleClose}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      ) : (
        ''
      )}
      {selectedValue && selectedValue === 'Category' ? (
        <Grid container alignItems="center" item xs={12} style={{ marginBottom: '3%' }}>
          <Grid item xs={9} align="left">
            <form className={classes.form} noValidate autoComplete="off">
              {/* <TextField id="standard-basic" label="Category" value={category} onChange={handleCategory} /> */}
              <Autocomplete
                options={allCategory.data}
                onChange={handleCategory}
                getOptionLabel={(option) => option.name}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // autoFocus
                    placeholder="Select A Category"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <>{params.InputProps.endAdornment}</>,
                    }}
                  />
                )}
              />
            </form>
          </Grid>
          <Grid item xs={3} align="left">
            <Button variant="contained" color="primary" onClick={handleEditCategory} style={{ marginRight: '2%' }}>
              Edit
            </Button>
            <Button variant="contained" color="secondary" onClick={handleClose} className={classes.margin}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs />
        </Grid>
      ) : (
        ''
      )}
      {selectedValue && selectedValue === 'Sub Category' ? (
        <Grid container alignItems="center" item xs={12} style={{ marginBottom: '3%' }}>
          <Grid item xs={9} align="left">
            <form className={classes.form} noValidate autoComplete="off">
              <Autocomplete
                options={allSubCategory.data}
                onChange={handleChangeSubCategory}
                getOptionLabel={(option) => option.name}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // autoFocus
                    placeholder="Select A Sub Category"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <>{params.InputProps.endAdornment}</>,
                    }}
                  />
                )}
              />
            </form>
          </Grid>
          <Grid item xs={3} align="left">
            <Button variant="contained" color="primary" onClick={handleEditSubCategory} style={{ marginRight: '2%' }}>
              Edit
            </Button>
            <Button variant="contained" color="secondary" onClick={handleClose} className={classes.margin}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs />
        </Grid>
      ) : (
        ''
      )}
      {selectedValue && selectedValue === 'Passive Id' ? (
        <Grid container alignItems="center" item xs={12} style={{ marginBottom: '3%' }}>
          <Grid item xs={9} align="left">
            <form className={classes.form} noValidate autoComplete="off">
              {/* <TextField id="standard-basic" label="Passive ID" value={passiveId} onChange={handlePassiveId} /> */}
              <Autocomplete
                options={interventions.data}
                onChange={handlePassiveId}
                getOptionLabel={(option) => option.name}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // autoFocus
                    placeholder="Select A Active Id"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <>{params.InputProps.endAdornment}</>,
                    }}
                  />
                )}
              />
            </form>
          </Grid>
          <Grid item xs={3} align="left">
            <Button variant="contained" color="primary" onClick={handleEditPassiveId} style={{ marginRight: '2%' }}>
              Edit
            </Button>
            <Button variant="contained" color="secondary" onClick={handleClose} className={classes.margin}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs />
        </Grid>
      ) : (
        ''
      )}
      {selectedValue && selectedValue === 'metadata' ? (
        <Grid container alignItems="center" item xs={12} style={{ marginBottom: '3%' }}>
          <Grid item xs={9} align="left">
            <form className={classes.form} noValidate autoComplete="off">
              <TextField id="standard-basic" label="How It Works" value={howitworks} onChange={handleHowitworks} />

              <div className="App">
                <div>
                  <input type="file" accept="image/*" onChange={onSelectFile} />
                </div>
                <ReactCrop src={upImg} onImageLoaded={onLoad} crop={crop} onChange={(c) => setCrop(c)} onComplete={(c) => setCompletedCrop(c)} />
                <div>
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      width: Math.round(completedCrop?.width ?? 0),
                      height: Math.round(completedCrop?.height ?? 0),
                    }}
                  />
                </div>
              </div>
            </form>
          </Grid>
          <Grid item xs={3} align="left">
            {file ? (
              <Button variant="contained" color="primary" onClick={handleUpload} style={{ marginRight: '2%' }}>
                Upload
              </Button>
            ) : (
              ''
            )}
            {show ? (
              <Button variant="contained" color="primary" onClick={handleEditMetadata} style={{ marginRight: '2%' }}>
                Edit
              </Button>
            ) : (
              ''
            )}
            {!file && !show ? (
              <Button variant="contained" color="primary" onClick={handleNonpost} style={{ marginRight: '2%' }}>
                Add
              </Button>
            ) : (
              ''
            )}

            <Button variant="contained" color="secondary" onClick={handleClose} className={classes.margin}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs />
        </Grid>
      ) : (
        ''
      )}
      {open ? (
        <Grid container alignItems="center" item xs={12} style={{ marginBottom: '3%' }}>
          <Grid item xs={9} align="left">
            <form className={classes.form} noValidate autoComplete="off">
              <TextField id="standard-basic" label="Name" onChange={handleName} value={name} />
              <TextField id="standard-basic" label="Description" value={description} onChange={handleDescription} />
              <Autocomplete
                options={allCategory.data}
                onChange={handleCategory}
                getOptionLabel={(option) => option.name}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // autoFocus
                    placeholder="Select A Category"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <>{params.InputProps.endAdornment}</>,
                    }}
                  />
                )}
              />
              <Autocomplete
                options={allSubCategory.data}
                onChange={handleChangeSubCategory}
                getOptionLabel={(option) => option.name}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // autoFocus
                    placeholder="Select A Sub Category"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <>{params.InputProps.endAdornment}</>,
                    }}
                  />
                )}
              />
              <Autocomplete
                options={interventions.data}
                onChange={handlePassiveId}
                getOptionLabel={(option) => option.name}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // autoFocus
                    placeholder="Select A Active Id"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <>{params.InputProps.endAdornment}</>,
                    }}
                  />
                )}
              />
            </form>
          </Grid>
          <Grid item xs={3} align="left">
            <Button variant="contained" color="primary" onClick={handlePost} style={{ marginRight: '2%' }}>
              Add
            </Button>
            <Button variant="contained" color="secondary" onClick={handleOpen} className={classes.margin}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs />
        </Grid>
      ) : (
        ''
      )}

      {searchValue && searchIntervention ? (
        searchIntervention.map((intervention, index) => {
          return (
            <Grid direction="column" key={index} className={classes.root}>
              <Grid container item xs={12} className={classes.paper}>
                <Grid item xs={4}>
                  <Grid direction="column" item xs={12}>
                    <Typography variant="body2">{intervention.name}</Typography>
                    <Typography variant="h6" className={classes.spacing}>
                      {intervention.description}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid direction="column" item xs={12}>
                    <Typography variant="body2">Category</Typography>
                    <Typography variant="h6" className={classes.spacing}>
                      {`${allCategory && allCategory.data.filter((cat) => cat._id === intervention.category)[0].name}`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid direction="column" item xs={12}>
                    <Typography variant="body2">Sub Category</Typography>
                    <Typography variant="h6" className={classes.spacing}>
                      {`${allSubCategory && allSubCategory.data.filter((cat) => cat._id === intervention.subCategory)[0].name}`}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={2}>
                  <Grid direction="column" item xs={12}>
                    <Typography variant="body2">Passive Id</Typography>
                    <Typography variant="h6" className={classes.spacing}>
                      {intervention.passiveId ? intervention.passiveId : ''}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  {/* <DeleteIcon
                    onClick={() => {
                      axios
                        .delete(`/interventionschemev2/${intervention._id}`)
                        .then(() => {
                          makeRequest();
                        })
                        .catch((err) => console.log(err, 'err'));
                    }}
                    style={{ cursor: 'pointer' }}
                  /> */}
                </Grid>
                <Grid item xs={1}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => {
                      if (e.target.value === 'none') {
                        setSelectedValue('');
                      } else {
                        setSelectedValue(e.target.value);
                        setId(intervention._id);
                      }
                    }}
                    defaultValue={selectedValue}
                  >
                    <MenuItem value="name">Name</MenuItem>
                    <MenuItem value="Description">Description</MenuItem>
                    <MenuItem value="Category">Category</MenuItem>
                    <MenuItem value="Sub Category">Sub Category</MenuItem>
                    <MenuItem value="Passive Id">Passive Id</MenuItem>
                    <MenuItem value="metadata">Metadata</MenuItem>
                    {/* <MenuItem value="All">All</MenuItem> */}
                    <MenuItem value="none">None</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          );
        })
      ) : interventions ? (
        interventions.data.map((intervention, index) => {
          return (
            <Grid direction="column" key={index} className={classes.root}>
              <Grid container item xs={12} className={classes.paper}>
                <Grid item xs={2}>
                  <Grid direction="column" item xs={12}>
                    <Typography variant="body2">{intervention.name}</Typography>
                    <Typography variant="h6" className={classes.spacing}>
                      {intervention.description}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  <Grid direction="column" item xs={12}>
                    <Typography variant="body2">Metadata</Typography>
                    <VisibilityIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        swal(`${intervention && intervention.metadata ? intervention.metadata.howitworks : ''}`);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid direction="column" item xs={12} style={{ marginLeft: '20px' }}>
                    <Typography variant="body2">Category</Typography>
                    <Typography variant="h6" className={classes.spacing}>
                      {`${allCategory && allCategory.data.filter((cat) => cat._id === intervention.category)[0].name}`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid direction="column" item xs={12}>
                    <Typography variant="body2">Sub Category</Typography>
                    <Typography variant="h6" className={classes.spacing}>
                      {`${allSubCategory && allSubCategory.data.filter((cat) => cat._id === intervention.subCategory)[0].name}`}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={2}>
                  <Grid direction="column" item xs={12}>
                    <Typography variant="body2">Passive Id</Typography>
                    <Typography variant="h6" className={classes.spacing}>
                      {intervention.passiveId ? intervention.passiveId : ''}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body2">Multiplier</Typography>
                  <VisibilityIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      swal(
                        `${
                          intervention && intervention.multipliers
                            ? `Area: ${intervention.multipliers.area}, Capex: ${intervention.multipliers.capex}, Resource: ${intervention.multipliers.resource}`
                            : ''
                        }`
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => {
                      if (e.target.value === 'none') {
                        setSelectedValue('');
                      } else {
                        setSelectedValue(e.target.value);
                        setId(intervention._id);
                        setAreaMultiplier(intervention.multipliers.area);
                        setCapexMultiplier(intervention.multipliers.capex);
                        setResourceMultiplier(intervention.multipliers.resource);
                      }
                    }}
                    defaultValue={selectedValue}
                  >
                    <MenuItem value="name">Name</MenuItem>
                    <MenuItem value="Description">Description</MenuItem>
                    <MenuItem value="Category">Category</MenuItem>
                    <MenuItem value="Sub Category">Sub Category</MenuItem>
                    <MenuItem value="Passive Id">Passive Id</MenuItem>
                    <MenuItem value="metadata">Metadata</MenuItem>
                    <MenuItem value="multipliers">Multipliers</MenuItem>
                    <MenuItem value="none">None</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          );
        })
      ) : (
        <>
          {data &&
            data.map(() => {
              return <Skeleton animation="wave" height={80} />;
            })}
        </>
      )}
    </>
  );
};
export default Interventions;
