import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Typography } from '@material-ui/core';
import CommonCardNew from '../common/commomCardNew';
import CasaSkeleton from '../../../../common/skeleton/CasaSkeleton';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
import WasteMandateImage from '../../../../../assets/interventions/WasteMandateImage.svg';
import configure from '../../../../../assets/interventions/listicon.svg';
import viewSetLogo from '../../../../../assets/interventions/ViewAllSetLogo.svg';
import saveSetLogo from '../../../../../assets/interventions/SaveCurrentSet.svg';
import AddInterventionSetModal from './AddInterventionSetModal';
import DataAndCostAssumptions from '../main/DataAndCostAssumptions';
import { getByProjectAndInterventionId } from '../../../../../http/projectIntervention';
import { useNotification } from '../../../../../v2/components/NotificationProvider';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles((theme) => ({
  paper2: {
    outline: 'none',
    minHeight: 70,
    minWidth: 160,
    padding: theme.spacing(2, 3, 2),
    marginTop: '1%',
    background: '#FFFFFF',
    borderRadius: '10px',
    height: 'auto',
    boxShadow: '2px 2px 8px rgba(24, 50, 119, 0.1)',
    position: 'relative',
  },
  typo: {
    marginLeft: '5%',
    cursor: 'pointer',
  },
  spacing: {
    marginTop: theme.spacing(3),
  },
  errorIcon: {
    color: '#66E3BE',
    cursor: 'pointer',
  },
  summarytext: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.5px',
    color: '#000000',
    paddingLeft: '5px',
  },
  floatingButtonStyle: {
    overflow: 'hidden',
    position: 'fixed',
    right: '0',
    bottom: '37%',
    padding: '100px',
  },
  image: {
    marginTop: '2px',
    width: '12px',
    height: '12px',
  },
  configure: {
    cursor: 'pointer',
    marginLeft: '10px',
    marginTop: '5px',
    color: '#00C58A',
    fontFamily: 'Lato',
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '14.4px',
    letterSpacing: '0.7143px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
  savePaper: {
    cursor: 'default',
    width: '8vw',
    height: 24,
    padding: '3px',
    backgroundColor: '#F7FDF8',
  },
  paper: {
    cursor: 'pointer',
    width: '8vw',
    height: 24,
    padding: '3px',
    backgroundColor: '#F0FBF2',
  },
  saveButton: {
    cursor: 'default',
    marginLeft: '5%',
    marginTop: '2px',
    color: '#00C58A',
    fontSize: '9px',
    fontWeight: 500,
    letterSpacing: '0.0643px',
    textAlign: 'center',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
  setButtons: {
    cursor: 'pointer',
    marginLeft: '5%',
    marginTop: '2px',
    color: '#00C58A',
    fontSize: '9px',
    fontWeight: 500,
    letterSpacing: '0.0643px',
    textAlign: 'center',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
  greyPaper: {
    marginTop: '6px',
    marginLeft: '7px',
    fontFamily: 'var(--LabelLargeFont)',
    fontSize: '9px',
    fontWeight: 500,
    lineHeight: '12.86px',
    letterSpacing: '0.0643px',
    textAlign: 'center',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
}));

function InterventionParametersOverview({ title, warning, mandateSummary, inputs, projectId, interventionId, projectIntervention }) {
  const classes = useStyles();
  const [openInterventionSetModal, setOpenInterventionSetModal] = useState(false);
  const [isShowConfigure, setIsShowConfigure] = useState(false);
  const { notifyFailure } = useNotification();
  let history = useHistory();

  const handleOpen = async () => {
    if (getAppliedSetName() === 'No Set Applied') {
      const projectIntervention = await getByProjectAndInterventionId(projectId, interventionId);
      if (projectIntervention.data.body.data.interventionSets.length < 4) {
        setOpenInterventionSetModal(true);
      } else notifyFailure('4/4 parameter sets already added. Please delete a parameter set to add more.');
    }
  };
  const handleClose = () => setOpenInterventionSetModal(false);

  const openConfigure = () => setIsShowConfigure(true);
  const closeConfigure = () => setIsShowConfigure(false);

  const getAppliedSetName = () => {
    const set = projectIntervention?.interventionSets?.find((set) => set._id === projectIntervention?.activeSetId);
    return set?.name || 'No Set Applied';
  };
  return (
    <>
      {inputs?.length ? (
        <>
          <Grid container xs={12} direction="row" style={{ overflow: 'hidden' }}>
            <Grid container direction="row" item xs={12}>
              <Typography style={{ marginRight: '1%' }} variant="h5">
                {title}
              </Typography>
              <Paper elevation={0} style={{ width: '10vw', height: 24, marginTop: '15px', backgroundColor: '#F7F7F7' }}>
                <Typography className={classes.greyPaper} container direction="row">
                  {getAppliedSetName()}
                </Typography>
              </Paper>
              <CustomizedTooltips
                marginTop="16px"
                icon="info"
                title={
                  <>
                    <Typography>
                      Explore different intervention results by changing key parameters.
                      <br />
                      As some parameters depend on each other, change only 1 at a time
                      <br />
                    </Typography>
                  </>
                }
              />
            </Grid>

            <Grid container direction="row" item xs={12}>
              {isShowConfigure && <DataAndCostAssumptions isOpen={isShowConfigure} onClose={closeConfigure} />}

              <Grid style={{ cursor: 'pointer', paddingBottom: '10px' }} onClick={openConfigure}>
                <img alt="Configure" src={configure} />
              </Grid>
              <Typography className={classes.configure} onClick={openConfigure}>
                CONFIGURE
              </Typography>

              <Grid
                onClick={handleOpen}
                direction="row"
                style={{
                  opacity: getAppliedSetName() !== 'No Set Applied' ? 0.5 : 1,
                  marginLeft: '3%',
                }}
                item
              >
                <Paper elevation={0} className={getAppliedSetName() === 'No Set Applied' ? classes.paper : classes.savePaper}>
                  <Grid container direction="row" justifyContent="center">
                    <img alt="Save Current Set" src={saveSetLogo} className={classes.image} />
                    <Typography className={getAppliedSetName() === 'No Set Applied' ? classes.setButtons : classes.saveButton}>Save Current Set</Typography>
                  </Grid>
                </Paper>
              </Grid>

              <Grid style={{ marginLeft: '1%' }}>
                <Paper
                  onClick={() => {
                    history.push(`/projects/${projectId}/interventions/${interventionId}/intervention-sets`);
                  }}
                  elevation={0}
                  className={classes.paper}
                >
                  <Grid container direction="row" justifyContent="center">
                    <img alt="View All Sets" src={viewSetLogo} className={classes.image} />
                    <Typography className={classes.setButtons}>View All Sets</Typography>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="row" xs={12}>
            <Grid xs={4} container direction="column" data-tour="parameter">
              {openInterventionSetModal && (
                <AddInterventionSetModal open={openInterventionSetModal} handleClose={handleClose} projectId={projectId} interventionId={interventionId} />
              )}
              {inputs ? (
                <CommonCardNew
                  title={title}
                  showTotalCapex={false}
                  inputs={inputs}
                  warning={warning}
                  height="auto"
                  width="auto"
                  tooltipReady={false}
                  tooltipBody={
                    <span>
                      System Capacity is expressed in number of incinerators required,
                      <br />
                      where each incinerator can handle up to 500 napkins.
                    </span>
                  }
                />
              ) : (
                <CasaSkeleton variant="rect" height="25vh" width={350} animation="wave" style={{ marginTop: '1%' }} />
              )}
            </Grid>
          </Grid>
          <Grid xs={7} container direction="column" data-tour="parameter" style={{ paddingTop: '10px', paddingLeft: '10px' }}>
            {mandateSummary && mandateSummary.length !== 0 && mandateSummary[0]?.value[0] !== '' ? (
              <Typography>
                <img src={WasteMandateImage} height="16" alt="..." /> <b className={classes.summarytext}>{mandateSummary && mandateSummary[0]?.value[0]}</b>
              </Typography>
            ) : (
              ''
            )}
          </Grid>
        </>
      ) : null}
    </>
  );
}

export default InterventionParametersOverview;
