import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import SecondaryImpactCard from './secondaryImpactCard';
import ContributionToGoalsCard from './contributionToGoalsCard';
import CasaSkeleton from '../../../../common/skeleton/CasaSkeleton';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
import { useInterventionDetailsContext } from '../context/InterventionDetailsV2';
import { LegalQuantityTypes } from '../../../../../metafire/utils/types';

const useStyles = makeStyles((theme) => ({
  impactgrid1: {
    marginTop: '2%',
  },
  errorIcon: {
    color: '#66E3BE',
    cursor: 'pointer',
  },
  card2title: {
    // marginLeft: '28px',
  },
}));
function InterventionImpactAndGoals(props) {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantitiesByTypeDefinition, getQuantityValue },
    meta: { goalsProgressPercentage },
    request: { isFetchingGoalFromMetafire },
    key,
  } = useInterventionDetailsContext();
  const inputs = getQuantitiesByTypeDefinition(LegalQuantityTypes.PassiveImpact);

  return (
    <>
      <Grid container alignItems="center" direction="row" wrap="nowrap" xs={12} className={classes.impactgrid1}>
        <Grid xs={6} item container direction="column">
          <Grid xs={12}>
            <Typography variant="h5">{props.title1}</Typography>
          </Grid>
          <Grid xs={12} item>
            {inputs && inputs ? (
              <SecondaryImpactCard inputs={inputs} height="120px" width="auto" />
            ) : (
              <CasaSkeleton variant="rect" animation="wave" style={{ minHeight: 120, minWidth: 530 }} />
            )}
          </Grid>
        </Grid>

        <Grid xs={4} style={{ marginLeft: '2%' }} item container direction="column">
          <Grid xs={12} container item>
            <Typography variant="h5">{props.title2}</Typography>
            <CustomizedTooltips
              marginTop="15px"
              icon="info"
              title={<Typography> View how this intervention contributes to the overall Goal set for this Resource category</Typography>}
              placement="right"
            />
          </Grid>
          <Grid xs={12} item>
            {!isFetchingGoalFromMetafire ? (
              <ContributionToGoalsCard
                featureKey={key}
                goalsProgressPercentage={goalsProgressPercentage}
                goalContribution={getQuantityValue(schema[`${key}_goalContribution`])}
                goalcontributioncolor={props.goalcontributioncolor}
                height="200px"
                width="auto"
              />
            ) : (
              <CasaSkeleton variant="rect" animation="wave" style={{ minHeight: 200, minWidth: 250 }} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default InterventionImpactAndGoals;
