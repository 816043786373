import React, { useState, useCallback, useRef, useEffect } from 'react';
import axios from '../../../../config/Axios';
import useApiRequest from '../../../hooks/useApiRequest';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import SearchBar from '../../../common/Searchbar/Search';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import swal from 'sweetalert';
import api from '../../../../http';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { getBaseImageUrl } from '../../../../utils/getBaseImageUrl';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: '2%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  heading2: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  form: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  doneicon: {
    color: 'green',
  },
  closeicon: {
    color: 'red',
  },
}));

function Vendors() {
  const [selectedValue, setSelectedValue] = useState('');
  const [vendors, setVendors] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [searchVendor, setSearchVendor] = useState('');
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const data = [1, 2, 3, 4, 5, 6];
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [asset, setAsset] = useState('');
  const [upload, setUpload] = useState(false);

  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [file, setFile] = useState('');
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 9 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [{ status, response }, makeRequest] = useApiRequest('/vendorsv2', {
    verb: 'get',
  });
  if (status === null) makeRequest();
  useEffect(() => {
    if (response) {
      setVendors(response.data.data);
    }
  }, [response]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleTextField = (e) => {
    setSearchValue(e.target.value);
    const newData = vendors && vendors.data.filter((category) => category.vendorname.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1);
    setSearchVendor(newData);
  };

  const handleName = (event) => setName(event.target.value);
  const handlePost = () => {
    let body = { vendorname: name, logo: asset && asset._id };
    axios
      .post(`/vendorsv2`, body)
      .then(() => {
        makeRequest();
        setName('');
        setFile('');
        setUpload(false);
        setOpen(!open);
      })
      .catch((err) => console.log(err, 'err'));
  };
  const handleOpen = () => {
    setName('');
    setOpen(!open);
    setFile('');
    setUpImg('');
    setCompletedCrop('');
  };

  const handleSelect = (e) => {
    if (e.target.value === 'none') {
      setSelectedValue('');
    } else {
      setSelectedValue(e.target.value);
    }
  };

  const handleSelectName = (vendorname, id) => {
    setName(vendorname);
    setId(id);
  };

  const handleUpload = async () => {
    if (file) {
      const signedUrl = await api.getVendorsPresignedURL(file.name);
      if (!signedUrl) throw new Error('Failed to get presigned URL');
      const uploadSuccessful = await api.putUploadToS3(signedUrl, file);
      if (uploadSuccessful?.status !== 200) throw new Error('Upload failed');
      const viewImageURL = getBaseImageUrl(signedUrl);
      if (viewImageURL) {
        const body = {
          URL: viewImageURL,
        };
        try {
          const response = await api.createStaticAsset(body);
          if (response && response.data.data) {
            setAsset(response.data.data.data);
            setShow(true);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };
  const handleSelected = () => {
    setUpImg('');
    setCompletedCrop('');
    setSelectedValue('none');
  };
  const handleSelectVendor = (vendorname, id) => {
    setName(vendorname);
    setId(id);
  };
  const handleNonupload = () => {
    swal({ icon: 'error', title: `Please Select an Image` });
    setName('');
  };
  const handleNonpost = () => {
    swal({ icon: 'error', title: `Please Select an Image` });
  };

  const handleUploadClick = (event) => {
    const file = event.target.files[0];
    setFile(file);
  };
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';
    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);
  }, [completedCrop]);

  return (
    <>
      <Grid container alignItems="center" item xs={12} style={{ marginBottom: '5%' }}>
        <Grid item xs={7} align="left">
          <Typography variant="h3">Vendors Management</Typography>
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={3}>
          <Link style={{ background: 'transparent', color: 'rgba(0, 219, 153, 0.82)', width: '30%' }} onClick={handleOpen}>
            Add Vendor <AddCircleIcon />
          </Link>
        </Grid>
      </Grid>
      <Grid container alignItems="center" item xs={12} style={{ marginBottom: '5%' }}>
        <Grid item xs={5} align="left">
          <SearchBar onChange={handleTextField} value={searchValue} placeholder="Search by Vendor name" />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={3} align="left"></Grid>
        <Grid item xs />
      </Grid>
      {selectedValue && selectedValue === 'name' ? (
        <>
          <Grid container alignItems="center" item xs={12} style={{ marginBottom: '3%' }}>
            <Grid item xs={6} align="left">
              <form className={classes.form} noValidate autoComplete="off">
                <TextField id="standard-basic" label="Vendor Name" onChange={handleName} value={name} />
              </form>
            </Grid>
            <Grid item xs={6} align="left">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  axios.put(`/vendorsv2/${id}`, { name: name }).then(() => {
                    makeRequest();
                    setSelectedValue('');
                    swal({ icon: 'success', title: `Vendor updated successfully` });
                  });
                }}
                style={{ marginRight: '2%' }}
              >
                Update
              </Button>
              <Button variant="contained" color="secondary" onClick={handleSelected} className={classes.margin}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs />
          </Grid>
        </>
      ) : selectedValue && selectedValue === 'logo' ? (
        <>
          <Grid container alignItems="center" item xs={12} style={{ marginBottom: '3%' }}>
            <Grid item xs={12} align="left">
              <Grid item xs={12}>
                <Typography variant="h5">Update Logo For {name}</Typography>
                <Grid container alignItems="center" item xs={12} style={{ marginBottom: '5%' }}>
                  <div className="App">
                    <div>
                      <input type="file" accept="image/*" onChange={onSelectFile} />
                    </div>
                    <ReactCrop src={upImg} onImageLoaded={onLoad} crop={crop} onChange={(c) => setCrop(c)} onComplete={(c) => setCompletedCrop(c)} />
                    <div>
                      <canvas
                        ref={previewCanvasRef}
                        style={{
                          width: Math.round(completedCrop?.width ?? 0),
                          height: Math.round(completedCrop?.height ?? 0),
                        }}
                      />
                    </div>
                    <button type="button" disabled={!completedCrop?.width || !completedCrop?.height}>
                      Download cropped image
                    </button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5} align="left">
              {file && !show ? (
                <Button variant="contained" color="primary" onClick={handleUpload} style={{ marginRight: '2%' }}>
                  Upload
                </Button>
              ) : (
                ''
              )}
              {show ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    axios.put(`/vendorsv2/${id}`, { staticAssetId: asset }).then(() => {
                      swal({ icon: 'success', title: `Vendor updated successfully` });
                      makeRequest();
                      setSelectedValue('');
                      setFile('');
                      setSelectedValue('');
                      setUpload(false);
                    });
                  }}
                  style={{ marginRight: '2%' }}
                >
                  Update
                </Button>
              ) : (
                ''
              )}
              {!file && !show ? (
                <Button variant="contained" color="primary" onClick={handleNonupload} style={{ marginRight: '2%' }}>
                  Upload
                </Button>
              ) : (
                ''
              )}
              <Button variant="contained" color="secondary" onClick={handleSelected} className={classes.margin}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs />
          </Grid>
        </>
      ) : null}
      {open ? (
        <>
          <Grid container alignItems="center" item xs={12} style={{ marginBottom: '3%' }}>
            <Grid item xs={6} align="left">
              <form className={classes.form} noValidate autoComplete="off">
                <TextField id="standard-basic" label="Vendor Name" onChange={handleName} value={name} />
                <Grid item xs={12}>
                  <div className="App">
                    <div>
                      <input type="file" accept="image/*" onChange={onSelectFile} />
                    </div>
                    <ReactCrop src={upImg} onImageLoaded={onLoad} crop={crop} onChange={(c) => setCrop(c)} onComplete={(c) => setCompletedCrop(c)} />
                    <div>
                      <canvas
                        ref={previewCanvasRef}
                        style={{
                          width: Math.round(completedCrop?.width ?? 0),
                          height: Math.round(completedCrop?.height ?? 0),
                        }}
                      />
                    </div>
                    <button type="button" disabled={!completedCrop?.width || !completedCrop?.height}>
                      Download cropped image
                    </button>
                  </div>
                </Grid>
              </form>
            </Grid>
            <Grid item xs={6} align="left">
              {file && !show ? (
                <Button variant="contained" color="primary" onClick={handleUpload} style={{ marginRight: '2%' }}>
                  Upload
                </Button>
              ) : (
                ''
              )}
              {show ? (
                <Button variant="contained" color="primary" onClick={handlePost} style={{ marginRight: '2%' }}>
                  Add
                </Button>
              ) : (
                ''
              )}
              {!file && !show ? (
                <Button variant="contained" color="primary" onClick={handleNonpost} style={{ marginRight: '2%' }}>
                  Add
                </Button>
              ) : (
                ''
              )}

              <Button variant="contained" color="secondary" onClick={handleOpen} className={classes.margin}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs />
          </Grid>
        </>
      ) : (
        ''
      )}
      {searchValue && searchVendor ? (
        searchVendor.map((vendor, index) => {
          return (
            <div className={classes.root}>
              <Accordion expanded={expanded === index} onChange={handleChange(index)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                  <Grid container spacing={0} alignItems="center" xs={12}>
                    <Grid item xs={3}>
                      <Typography className={classes.secondaryHeading}>
                        <b>{vendor.vendorname.toUpperCase()}</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography className={classes.secondaryHeading}>
                        <b>
                          <a target="_blank" href={vendor.logo ? vendor.logo.URL : null}>
                            {vendor.logo ? vendor.logo.URL : 'No Logo Available'}
                          </a>
                        </b>
                      </Typography>
                    </Grid>
                    <Grid item xs={1} align="center">
                      <Typography className={classes.heading2}>
                        <b>Update</b>
                      </Typography>
                      <br />
                      <Select labelId="demo-simple-select-label" id="demo-simple-select" onChange={handleSelect} defaultValue={selectedValue}>
                        <MenuItem value="name" onClick={() => handleSelectName(vendor.vendorname, vendor._id)}>
                          Name
                        </MenuItem>
                        <MenuItem value="logo" onClick={() => handleSelectVendor(vendor.vendorname, vendor._id)}>
                          Logo
                        </MenuItem>
                        <MenuItem value="none">None</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container item xs={12} direction="column">
                    <Typography variant="h6">Delete</Typography>
                    <DeleteIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        axios
                          .delete(`/vendorsv2/${vendor._id}`)
                          .then(() => {
                            makeRequest();
                            swal({ icon: 'success', title: `Vendor deleted successfully` });
                          })
                          .catch((err) => console.log(err, 'err'));
                      }}
                    />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })
      ) : vendors ? (
        vendors.data.map((vendor, index) => {
          return (
            <div className={classes.root}>
              <Accordion expanded={expanded === index} onChange={handleChange(index)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                  <Grid container spacing={0} alignItems="center">
                    <Grid item xs={3}>
                      <Typography className={classes.secondaryHeading}>
                        <b>{vendor.vendorname.toUpperCase()}</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography className={classes.secondaryHeading}>
                        <b>
                          <a target="_blank" href={vendor.logo ? vendor.logo.URL : null}>
                            {vendor.logo ? vendor.logo.URL : 'No Logo Available'}
                          </a>
                        </b>
                      </Typography>
                    </Grid>
                    <Grid item xs={1} align="center">
                      <Typography className={classes.heading2}>
                        <b>Update</b>
                      </Typography>
                      <br />
                      <Select labelId="demo-simple-select-label" id="demo-simple-select" onChange={handleSelect} defaultValue={selectedValue}>
                        <MenuItem value="name" onClick={() => handleSelectName(vendor.vendorname, vendor._id)}>
                          Name
                        </MenuItem>
                        <MenuItem value="logo" onClick={() => handleSelectVendor(vendor.vendorname, vendor._id)}>
                          Logo
                        </MenuItem>
                        <MenuItem value="none">None</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container item xs={12} direction="column">
                    <Typography variant="h6">Delete</Typography>
                    <DeleteIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        axios
                          .delete(`/vendorsv2/${vendor._id}`)
                          .then(() => {
                            makeRequest();
                            swal({ icon: 'success', title: `Vendor deleted successfully` });
                          })
                          .catch((err) => console.log(err, 'err'));
                      }}
                    />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })
      ) : (
        <>
          {data &&
            data.map(() => {
              return <Skeleton animation="wave" height={50} />;
            })}
        </>
      )}
    </>
  );
}
export default Vendors;
