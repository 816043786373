import * as user from './user';
import * as project from './project';
import * as auth from './auth';
import * as organisation from './organisation';
import * as s3 from './s3';
import * as admin from './admin';
import * as customIntervention from './customIntervention';
import * as interventionDetails from './interventionDetails';
import * as projectIntervention from './projectIntervention';
import * as intervention from './intervention';
import * as noteTaking from './noteTaking';
import * as fileUpload from './fileUpload';

const apiList = {
  ...user,
  ...project,
  ...auth,
  ...organisation,
  ...s3,
  ...admin,
  ...customIntervention,
  ...interventionDetails,
  ...projectIntervention,
  ...intervention,
  ...noteTaking,
  ...fileUpload,
};

export default apiList;
